/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { DbModel, DocumentId } from "./Models";
import { getDb, saveModel, ensureObjectId, indexById } from "./utils";

type QueryHookReturnOne<T> = [
  T | null,
  boolean,
  (id?: DocumentId) => Promise<any>
];

type QueryHookReturnMany<T> = [
  T,
  boolean,
  (query?: object, sort?: object) => Promise<any>
];

type DefaultLoadCallback = (pipeline?: object[]) => Promise<any>;

export type AggregateHookReturn<T, LoadCallback = DefaultLoadCallback> = [
  T,
  boolean,
  LoadCallback
];

interface UseManyOptions<T = boolean> {
  loadNow?: boolean;
  indexById?: T;
}

interface RepositoryInterface<Model extends DbModel> {
  useOne(id?: DocumentId): QueryHookReturnOne<Model>;
  useMany(
    query?: object,
    sort?: object,
    options?: UseManyOptions<true>
  ): QueryHookReturnMany<KV<Model>>;
  useMany(
    query?: object,
    sort?: object,
    options?: UseManyOptions<false>
  ): QueryHookReturnMany<Model[]>;
  useMany(
    query?: object,
    sort?: object,
    options?: UseManyOptions
  ): QueryHookReturnMany<Model[] | KV<Model>>;
  save: (obj: Model) => Promise<Model>;
  delete: (id: DocumentId) => Promise<any>;
}

class Repository<Model extends DbModel> implements RepositoryInterface<Model> {
  collection: string;

  constructor(collection: string) {
    this.collection = collection;
  }

  protected getCollection<T = Model>() {
    return getDb().collection<T>(this.collection);
  }

  public useMany(
    query?: object,
    sort?: object,
    options?: UseManyOptions<false>
  ): QueryHookReturnMany<Model[]>;
  public useMany(
    query?: object,
    sort?: object,
    options?: UseManyOptions<true>
  ): QueryHookReturnMany<KV<Model>>;
  public useMany(
    query?: object,
    sort?: object,
    options?: UseManyOptions
  ): QueryHookReturnMany<Model[] | KV<Model>> {
    if (!query) {
      query = {};
    }
    if (!sort) {
      sort = { createdAt: 1 };
    }
    const [initialSort, setInitialSort] = React.useState<object>();
    const [initialQuery, setInitialQuery] = React.useState<object>();
    const [
      initialOptions,
      setInitialOptions,
    ] = React.useState<UseManyOptions>();
    const [data, setData] = React.useState<Model[] | KV<Model>>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const load = React.useCallback(
      async (query?: object, sort?: object) => {
        sort = sort || initialSort;
        query = query || initialQuery;
        setIsLoading(true);
        const newdata = await this.getCollection()
          .find(query, { sort })
          .asArray();
        if (initialOptions?.indexById) {
          setData(indexById(newdata));
        } else {
          setData(newdata);
        }

        setIsLoading(false);
      },
      [initialSort, initialQuery, initialOptions]
    );

    useDeepCompareEffect(() => {
      setInitialSort(sort);
      setInitialQuery(query);
      setInitialOptions(options);
      setData([]);
      if (options?.loadNow !== false) {
        setIsLoading(true);
        load(query, sort);
      }
    }, [query, sort, options]);
    return [data, isLoading, load];
  }

  public useAggregate<AggregateModel>(
    pipeline?: object[],
    options?: UseManyOptions<false>
  ): AggregateHookReturn<AggregateModel[]>;
  public useAggregate<AggregateModel>(
    pipeline?: object[],
    options?: UseManyOptions<true>
  ): AggregateHookReturn<KV<AggregateModel>>;
  public useAggregate<AggregateModel>(
    pipeline?: object[],
    options?: UseManyOptions
  ): AggregateHookReturn<AggregateModel[] | KV<AggregateModel>> {
    const [initialPipeline, setInitialPipeline] = React.useState<object[]>([]);
    const [
      initialOptions,
      setInitialOptions,
    ] = React.useState<UseManyOptions>();
    const [data, setData] = React.useState<
      AggregateModel[] | KV<AggregateModel>
    >([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const load = React.useCallback(
      async (pipeline?: object[]) => {
        pipeline = pipeline || initialPipeline;
        setIsLoading(true);
        const newdata = await this.getCollection<AggregateModel>()
          .aggregate(pipeline)
          .asArray();
        if (initialOptions?.indexById) {
          setData(indexById(newdata));
        } else {
          setData(newdata);
        }

        setIsLoading(false);
      },
      [initialPipeline, initialOptions]
    );

    useDeepCompareEffect(() => {
      setInitialPipeline(pipeline || []);
      setInitialOptions(options);
      setData([]);
      if (options?.loadNow !== false) {
        setIsLoading(true);
        load(pipeline);
      }
    }, [pipeline, options]);
    return [data, isLoading, load];
  }

  public useOne(id?: DocumentId): QueryHookReturnOne<Model> {
    const [data, setData] = React.useState<Model | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);

    const load = React.useCallback(async (id?: DocumentId) => {
      if (id) {
        setIsLoading(true);
        setData(
          await this.getCollection().findOne({ _id: ensureObjectId(id) })
        );
        setIsLoading(false);
      } else {
        setData(null);
        setIsLoading(false);
      }
    }, []);

    React.useEffect(() => {
      load(id);
    }, [id, load]);

    return [data, isLoading, load];
  }

  public save(obj: Model): Promise<Model> {
    return saveModel(obj, this.getCollection());
  }

  public delete(id: DocumentId) {
    return this.getCollection().deleteOne({ _id: ensureObjectId(id) });
  }
}

export default Repository;
