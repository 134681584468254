import * as Icons from "@material-ui/icons";

interface AssetType {
  icon: Icons.SvgIconComponent;
}

const assetNewTypes: { [key: string]: AssetType } = {
  camping: { icon: Icons.BeachAccess },
  casino: { icon: Icons.Casino },
  caves: { icon: Icons.Landscape },
  equestrianCenter: { icon: Icons.DirectionsRun },
  castle: { icon: Icons.AccountBalance },
  cinema: { icon: Icons.Theaters },
  clinic: { icon: Icons.LocalHospital },
  holidayClub: { icon: Icons.BeachAccess },
  school: { icon: Icons.School },
  pond: { icon: Icons.AddCircle },
  farm: { icon: Icons.NaturePeople },
  industrialWasteland: { icon: Icons.IndeterminateCheckBox },
  golf: { icon: Icons.GolfCourse },
  dependency: { icon: Icons.StoreMallDirectory },
  hotel: { icon: Icons.Hotel },
  serviceResidence: { icon: Icons.Domain },
  commonAreas: { icon: Icons.Domain },
  religiousBuilding: { icon: Icons.AccountBalance },
  ruin: { icon: Icons.TrendingDown },
  stadium: { icon: Icons.AddCircle },
  subsoil: { icon: Icons.LayersClear },
};

export default assetNewTypes;
