import { getDb, getUserOrg } from "./utils";
import { DbIncrement } from "./Models";

export const getNextIncrement = (key: string): Promise<number> => {
  const coll = getDb().collection<DbIncrement>("increments");
  return coll
    .findOneAndUpdate(
      { key, org: getUserOrg() },
      { $inc: { value: 1 } },
      { upsert: true }
    )
    .then(r => {
      if (r && r.value) {
        return r.value + 1;
      } else {
        return 1;
      }
    });
};
