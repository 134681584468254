import React from "react";
import { StorageInterface } from "../Storage";
import OrgRepository from "../db/OrgRepository";
import DropBoxStorage from "./DropBoxStorage";

interface StorageContextInterface {
  adapter?: StorageInterface;
}

const StorageContext = React.createContext<StorageContextInterface>({});

interface Props {
  path?: string;
}

const StorageProvider: React.FC<Props> = ({ path, children }) => {
  const [org, loading] = OrgRepository.useMany();
  const [adapter, setAdapter] = React.useState<StorageInterface>();

  React.useEffect(() => {
    if (org && org[0] && org[0].dropboxToken) {
      setAdapter(new DropBoxStorage(path || "/", org[0].dropboxToken));
    }
  }, [org, path]);

  return (
    <StorageContext.Provider value={{ adapter }}>
      {(loading && <div></div>) || children}
    </StorageContext.Provider>
  );
};

export { StorageContext, StorageProvider };
