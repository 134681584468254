// useDropboxDownloader.ts
// import { useDropboxDownloader } from "lib/hooks/useDropboxDownloader";
// const handleDownload = useDropboxDownloader();
import { useCallback } from 'react';
import DropBoxStorage from "lib/storage/DropBoxStorage";
import OrgRepository from "lib/db/OrgRepository";
import { StorageFile } from "lib/Storage";
import React from "react";

export const useDropboxDownloader = () => {
    const [org, , loadOrg] = OrgRepository.useMany();
  
    React.useEffect(() => {
      loadOrg();
    }, [loadOrg]);
  
    const handleDownload = useCallback((file: StorageFile) => () => {
      if (org && org.length > 0 && org[0].dropboxToken) {
        const dropboxStorage = new DropBoxStorage(file.path, org[0].dropboxToken);
        
        dropboxStorage.previewUrl(file).then(fileUrl => {
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(fileUrl);
        }).catch(error => {
          console.error("Erreur lors du téléchargement du fichier :", error);
        });
      } else {
        console.error("Le token Dropbox n'est pas chargé ou n'est pas valide.");
      }
    }, [org]);
  
    return handleDownload;
  };