import React from "react";
import { v4 as uuidv4 } from "uuid";
import { StorageInterface, onUploadCallback } from "../Storage";

type add = (file: File, path?: string) => void;
type cancel = (uuid: string) => void;

interface QueueItem {
  uuid: string;
  path?: string;
  file: File;
  progress: number;
  message: string;
  error: boolean;
}

type QueueState = QueueItem[];

const initialState: QueueState = [];

enum QueueReducerActionType {
  PUSH,
  PROGRESS,
  REMOVE,
  ERROR,
}

type QueueReducerAction =
  | { type: QueueReducerActionType.PUSH; file: File; path?: string }
  | { type: QueueReducerActionType.PROGRESS; uuid: string; progress: number }
  | { type: QueueReducerActionType.REMOVE; uuid: string }
  | { type: QueueReducerActionType.ERROR; uuid: string; message: string };

function queueReducer(state: QueueState, action: QueueReducerAction) {
  switch (action.type) {
    case QueueReducerActionType.PUSH:
      const item: QueueItem = {
        uuid: uuidv4(),
        path: action.path,
        file: action.file,
        progress: -1,
        message: "En attente de chargement",
        error: false,
      };
      return [...state, item];
    case QueueReducerActionType.PROGRESS:
      return state.map(i => {
        if (i.uuid === action.uuid) {
          i.progress = action.progress;
          i.message = "Transfert en cours";
        }
        return i;
      });
    case QueueReducerActionType.ERROR:
      return state.map(i => {
        if (i.uuid === action.uuid) {
          i.progress = -1;
          i.error = true;
          i.message = action.message;
        }
        return i;
      });
    case QueueReducerActionType.REMOVE:
      return state.filter(i => i.uuid !== action.uuid);
    default:
      throw new Error();
  }
}

const useUploadQueue = (
  storage: StorageInterface,
  onUpload: onUploadCallback
): [QueueState, add, cancel] => {
  const [queue, dispatchQueue] = React.useReducer(queueReducer, initialState);

  const add = React.useCallback(
    (file: File, path?: string) => {
      dispatchQueue({ type: QueueReducerActionType.PUSH, file, path });
    },
    [dispatchQueue]
  );

  const cancel = React.useCallback(
    (uuid: string) => {
      dispatchQueue({ type: QueueReducerActionType.REMOVE, uuid });
    },
    [dispatchQueue]
  );

  React.useEffect(() => {
    if (queue.length && queue.every(i => i.progress === -1)) {
      const item = queue.find(i => !i.error);
      if (!item) {
        // There is only error in queue
        return;
      }
      storage
        .upload(item.file, item.path || "", function (progress) {
          dispatchQueue({
            type: QueueReducerActionType.PROGRESS,
            progress: progress,
            uuid: item.uuid,
          });
        })
        .then(path => {
          dispatchQueue({
            type: QueueReducerActionType.REMOVE,
            uuid: item.uuid,
          });
          onUpload({ name: item.file.name, path });
        })
        .catch(err => {
          console.error(err);
          dispatchQueue({
            type: QueueReducerActionType.ERROR,
            uuid: item.uuid,
            message: err,
          });
        });
    }
  }, [queue, storage, onUpload]);

  return [queue, add, cancel];
};

export default useUploadQueue;
