import React from "react";
import { Marker } from "react-mapbox-gl";
import { Props as MarkerProps } from "react-mapbox-gl/lib/marker";
import { makeStyles } from "@material-ui/core";
import assetTypes from "./assetTypes";

const useStyles = makeStyles({
  marker: {
    width: 25,
    height: 25,
    fontWeight: "bold",
    borderRadius: "50%",
    backgroundColor: "#ff0000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
  },
  icon: {
    fontSize: 16,
    verticalAlign: "middle",
  },
});

export interface MapMarkerProps extends MarkerProps {
  count: number;
  type: string;
}

const DataMarker: React.FunctionComponent<MapMarkerProps> = ({
  count,
  type,
  ...rest
}) => {
  const classes = useStyles();
  const AssetIcon = assetTypes[type] ? assetTypes[type].icon : undefined;
  return (
    <Marker className={classes.marker} {...rest}>
      <div>
        {count === 1 && AssetIcon ? (
          <AssetIcon className={classes.icon} />
        ) : (
          count
        )}
      </div>
    </Marker>
  );
};

export default DataMarker;
