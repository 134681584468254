import { throttle } from "lodash";
import { Autocomplete } from "mui-rff";
import React from "react";

const geocoderApiOptions = {
  endpoint: "https://api.tiles.mapbox.com",
  source: "mapbox.places",
  accessToken:
    "pk.eyJ1IjoidGhzZHJvdW90IiwiYSI6ImNrZTFneXNvbTF1cm0yc3FpYnVlcXlkbjMifQ.kxF937MEGYcfQl2mq-o0bQ",
  country: "fr",
  language: "fr",
  types: "address",
  proximity: "2.35183,48.85658",
};

type SearchCallback = (data: any, searchTime: Date) => void;

function search(query: string, callback: SearchCallback) {
  var searchTime = new Date();
  var uri =
    geocoderApiOptions.endpoint +
    "/geocoding/v5/" +
    geocoderApiOptions.source +
    "/" +
    encodeURIComponent(query) +
    ".json" +
    "?access_token=" +
    geocoderApiOptions.accessToken +
    (geocoderApiOptions.proximity
      ? "&proximity=" + geocoderApiOptions.proximity
      : "") +
    (geocoderApiOptions.types
      ? "&types=" + encodeURIComponent(geocoderApiOptions.types)
      : "") +
    (geocoderApiOptions.types
      ? "&country=" + encodeURIComponent(geocoderApiOptions.country)
      : "") +
    (geocoderApiOptions.language
      ? "&language=" + encodeURIComponent(geocoderApiOptions.language)
      : "");

  fetch(uri).then(function (response) {
    return response.json().then(function (json) {
      callback(json.features, searchTime);
    });
  });
}

const GeocodeField: React.FunctionComponent<any> = ({
  name,
  label,
  required,
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<object[]>([]);

  const fetch = React.useMemo(
    () =>
      throttle((query, callback) => {
        search(query, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      return undefined;
    }

    fetch(inputValue, (results: object[]) => {
      if (active) {
        let newOptions: object[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      name={name}
      label={label}
      options={options}
      required={required}
      filterOptions={x => x}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={o => o.place_name || ""}
      autoComplete
      includeInputInList
      filterSelectedOptions
      freeSolo
    />
  );
};

export default GeocodeField;
