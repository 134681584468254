import React from "react";
import { makeStyles, Theme, Container, Card } from "@material-ui/core";
import ScreenStack from "components/ScreenStack";
import InvoiceForm from "./InvoiceForm";
import useMessage from "lib/hooks/useMessage";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
}));

interface Props {
  supplier: boolean;
}

const Invoice: React.FunctionComponent<Props> = ({ supplier }) => {
  const t = useMessage();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Card>
          <ScreenStack rootLabel={t("invoices.label")}>
            <InvoiceForm supplier={supplier} />
          </ScreenStack>
        </Card>
      </Container>
    </div>
  );
};

export default Invoice;
