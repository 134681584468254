import React from "react";
import ContactRepository from "lib/db/ContactRepository";
import EditCard from "components/EditCard";
import { useHistory, useParams } from "react-router-dom";
import ContactFields, { contactFieldsValidate } from "./ContactFields";
import useMessage from "lib/hooks/useMessage";
import { useScreenStack } from "components/ScreenStack";

interface Params {
  id?: string;
}

const ContactForm: React.FunctionComponent<{}> = () => {
  const [title, setTitle] = React.useState("Création client");
  const [contact, isLoading, load] = ContactRepository.useOne();
  const params = useParams<Params>();
  const history = useHistory();
  const stack = useScreenStack();
  const t = useMessage();

  const handleCancel = () => {
    if (stack.pop) {
      stack.pop();
    } else {
      history.push("/contacts");
    }
  };

  const handleSave = (values: any) => {
    return ContactRepository.save(values).then(o => {
      if (stack.pop) {
        stack.pop(o._id);
      } else {
        history.push("/contacts");
      }
    });
  };

  let handleDelete;
  if (contact && contact._id) {
    handleDelete = () => {
      return ContactRepository.delete(contact._id!).then(() =>
        history.push("/contacts")
      );
    };
  }

  React.useEffect(() => {
    if (params.id && !stack.pop) {
      setTitle(t("contacts.edit"));
      load(params.id);
    } else {
      setTitle(t("contacts.create"));
      load();
    }
  }, [params.id, load, t, stack.pop]);

  return (
    <EditCard
      title={title}
      values={contact}
      loading={isLoading}
      onValidate={contactFieldsValidate}
      onCancel={handleCancel}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <ContactFields />
    </EditCard>
  );
};

export default ContactForm;
