import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { DbContact } from "lib/db/Models";
import { blue } from "@material-ui/core/colors";
import { contactAvatarText, contactLabelHtml } from "lib/utils/Contact";

const useStyles = makeStyles({
  avatar: {
    color: blue[900],
    backgroundColor: blue[50],
  },
});

type onEditCallback = (id: string) => void;

interface Props {
  contact: DbContact;
  onEdit?: onEditCallback;
}

const ContactItem: React.FunctionComponent<Props> = ({ contact, onEdit }) => {
  const classes = useStyles();

  const handleEdit = (id: string) => () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const id = contact._id!.toHexString();
  const avatar = (
    <Avatar className={classes.avatar}>{contactAvatarText(contact)}</Avatar>
  );
  const primary = (
    <span dangerouslySetInnerHTML={{ __html: contactLabelHtml(contact) }} />
  );

  const secondary = (
    <>
      {contact.zipCode} {contact.city}
    </>
  );

  return (
    <>
      <ListItem onClick={handleEdit(id)} button>
        <ListItemAvatar>{avatar}</ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default ContactItem;
