import { DbInvoice } from "../db/Models";
import DateFnsUtils from "@date-io/date-fns";
import escapeHtml from "escape-html";
import {
  ShoppingCart as CustomerInvoiceIcon,
  LocalShipping as SupplierInvoiceIcon,
} from "@material-ui/icons";

const date = new DateFnsUtils();

export function invoiceLabelHtml(invoice: DbInvoice): string {
  let text = date.format(invoice.date, "dd/MM/yyyy");
  if (invoice.folder) {
    text += ` — ${escapeHtml(invoice.folder)}`;
  }
  text += ` —  ${invoice.total.toFixed(2)}€`;
  return text;
}

export function invoiceTypeIcon(invoice: DbInvoice) {
  return invoice.supplier ? SupplierInvoiceIcon : CustomerInvoiceIcon;
}
