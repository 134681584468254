import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Button,
  Menu,
  MenuItem,
  Hidden,
} from "@material-ui/core";
import {
  AccountCircle as UserAccountIcon,
  ContactPhone as ContactIcon,
  ShoppingCart as CustomerInvoiceIcon,
  LocalShipping as SupplierInvoiceIcon,
  AccountBalance as BankStatementIcon,
  SyncAlt as BankOperationIcon,
  People as UserIcon,
  Settings as SettingsIcon,
  Business as AssetIcon,
} from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { Stitch } from "mongodb-stitch-browser-sdk";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import useMessage from "./lib/hooks/useMessage";

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "white",
  },
  buttonSm: {
    color: "white",
  },
  buttonActive: {
    background: theme.palette.primary.light,
  },
  spacer: {
    flexGrow: 1,
  },
  menu: {},
}));

const AppBar: React.FunctionComponent<{}> = () => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | undefined>();
  const { pathname } = useLocation();
  const t = useMessage();
  const classes = useStyles();

  const handleDisconnect = () => {
    Stitch.defaultAppClient.auth.logout();
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(undefined);
  };

  const renderButton = (to: string, label: string, icon: React.ReactNode) => {
    const active = pathname.startsWith(to);
    return (
      <>
        <Hidden smDown>
          <Button
            startIcon={icon}
            className={clsx(
              classes.button,
              active ? classes.buttonActive : null
            )}
            component={Link}
            to={to}
          >
            {label}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={clsx(
              classes.buttonSm,
              active ? classes.buttonActive : null
            )}
            component={Link}
            to={to}
          >
            {icon}
          </IconButton>
        </Hidden>
      </>
    );
  };

  return (
    <MuiAppBar position="static">
      <Toolbar variant="dense">
        <div className={classes.menu}>
          {renderButton(
            "/customer-invoices",
            t("appbar.customer-invoices"),
            <CustomerInvoiceIcon />
          )}
          {renderButton(
            "/supplier-invoices",
            t("appbar.supplier-invoices"),
            <SupplierInvoiceIcon />
          )}
          {renderButton("/contacts", t("appbar.contacts"), <ContactIcon />)}
          {renderButton(
            "/bankstatements",
            t("appbar.bankstatements"),
            <BankStatementIcon />
          )}
          {renderButton("/bankops", t("appbar.bankops"), <BankOperationIcon />)}
          {renderButton("/assets", t("appbar.assets"), <AssetIcon />)}
          {Stitch.defaultAppClient.auth.user?.customData.level === "admin" &&
            renderButton("/users", t("appbar.users"), <UserIcon />)}
          {Stitch.defaultAppClient.auth.user?.customData.level === "admin" &&
            renderButton("/settings", t("appbar.settings"), <SettingsIcon />)}
        </div>
        <div className={classes.spacer}></div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenMenu}
          color="inherit"
        >
          <UserAccountIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
          open={Boolean(menuAnchor)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleDisconnect}>
            <FormattedMessage id="appbar.disconnect" />
          </MenuItem>
        </Menu>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
