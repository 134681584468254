import React from "react";
import ReactLoadingOverlay from "react-loading-overlay";
import { CircularProgress } from "@material-ui/core";

interface Props {
  loading?: boolean;
  className?: string;
}

const LoadingOverlay: React.FunctionComponent<Props> = ({
  loading,
  className,
  children,
}) => (
  <ReactLoadingOverlay
    active={Boolean(loading)}
    className={className}
    fadeSpeed={50}
    styles={{
      overlay: (base: any) => ({
        ...base,
        background: "white",
      }),
    }}
    spinner={<CircularProgress />}
  >
    {children}
  </ReactLoadingOverlay>
);

export default LoadingOverlay;
