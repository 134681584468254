import React from "react";
import {
  Breadcrumbs,
  Chip,
  withStyles,
  emphasize,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import { ObjectId } from "bson";
import clsx from "clsx";

type onPopCallback = (value?: any) => void;
type PushCallback = (
  label: string,
  node: React.ReactNode,
  onPop: onPopCallback
) => void;
type PopCallback = (value?: any) => void;

interface ScreenInterface {
  label: string;
  node: React.ReactNode;
  onPop?: onPopCallback;
}

interface ScreenStackContextInterface {
  stack: ScreenInterface[];
  push: PushCallback;
  pop?: PopCallback;
}

export const ScreenStackContext = React.createContext<
  ScreenStackContextInterface
>({ stack: [], push: () => {} });

export const useScreenStack = (): ScreenStackContextInterface => {
  const { stack, push, pop } = React.useContext(ScreenStackContext);
  // To disable pop on intial screen we do not return pop callback
  return { stack, push, pop: stack.length > 1 ? pop : undefined };
};

interface Props {
  rootLabel: string;
}

const StyledBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumb: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  hiddenScreen: {
    display: "none",
  },
}));

const ScreenStack: React.FunctionComponent<Props> = ({
  children,
  rootLabel,
}) => {
  const [stack, setStack] = React.useState<ScreenInterface[]>([
    { label: rootLabel, node: children },
  ]);
  const classes = useStyles();

  const push: PushCallback = (
    label: string,
    node: React.ReactNode,
    onPop: onPopCallback
  ) => {
    setStack(s => [...s, { label, node, onPop }]);
  };

  const pop: PopCallback = (id?: ObjectId) => {
    const screen = stack[stack.length - 1];
    setStack(s => s.slice(0, -1));
    if (screen && screen.onPop) {
      screen.onPop(id);
    }
  };

  const goBack = (index: number) => {
    setStack(s => s.slice(0, index + 1));
  };

  const breadcumb = stack.slice(0, -1);
  return (
    <ScreenStackContext.Provider value={{ stack, push, pop }}>
      {Boolean(breadcumb.length) && (
        <Breadcrumbs
          className={classes.breadcrumb}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcumb.map((screen, index) => (
            <StyledBreadcrumb
              key={index}
              label={screen.label}
              onClick={() => goBack(index)}
            />
          ))}
        </Breadcrumbs>
      )}
      {stack.map((screen, index) => (
        <div
          key={index}
          className={clsx({
            [classes.hiddenScreen]: index !== stack.length - 1,
          })}
          aria-hidden={index !== stack.length - 1}
        >
          {screen.node}
        </div>
      ))}
    </ScreenStackContext.Provider>
  );
};

export default ScreenStack;
