import {
  Stitch,
  RemoteMongoClient,
  RemoteMongoCollection,
} from "mongodb-stitch-browser-sdk";
import { ObjectId } from "bson";
import { DbModel, DocumentId } from "./Models";

function generateVersion(): string {
  return new Date().toISOString();
}

export function saveModel<T extends DbModel>(
  o: T,
  coll: RemoteMongoCollection<T>
) {
  if (o.tmpProps) {
    delete o.tmpProps;
  }
  if (o._id) {
    return coll
      .updateOne(
        {
          _id: o._id,
          version: o.version,
        },
        {
          ...o,
          version: generateVersion(),
          modifiedAt: new Date(),
        }
      )
      .then(r => {
        if (r.modifiedCount === 1) {
          o._id = r.upsertedId;
          return Promise.resolve(o);
        }
        return Promise.reject(new Error("Not found"));
      });
  } else {
    return coll
      .insertOne({
        ...o,
        org: getUserOrg(),
        version: generateVersion(),
        createdAt: new Date(),
        modifiedAt: new Date(),
      })
      .then(r => {
        o._id = r.insertedId;
        return Promise.resolve(o);
      });
  }
}

export function getDb() {
  return Stitch.defaultAppClient
    .getServiceClient(RemoteMongoClient.factory, "mongodb-atlas")
    .db(process.env.REACT_APP_DB_NAME || "");
}

export function getUserOrg(): string {
  return Stitch.defaultAppClient.auth.user?.customData["org"] || "";
}

export function ensureObjectId(id: DocumentId): ObjectId {
  return typeof id === "string" ? ObjectId.createFromHexString(id) : id;
}

export function indexById<T extends DbModel>(o: T[]): { [k: string]: T } {
  return o.reduce<{ [k: string]: T }>((prev, cur) => {
    prev[cur._id!.toHexString()] = cur;
    return prev;
  }, {});
}

export enum SORT_DIRECTION {
  SORT_ASC = 1,
  SORT_DESC = -1,
}
