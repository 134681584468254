import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { DbInvoice } from "lib/db/Models";
import InvoiceSelect from "./InvoiceSelect";
import ScreenStack from "components/ScreenStack";

type onSelectCallback = (value: DbInvoice | undefined | null) => void;

interface Props {
  open: boolean;
  onSelect: onSelectCallback;
}

const InvoiceSelectDialog: React.FunctionComponent<Props> = ({
  open,
  onSelect,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={() => onSelect(undefined)}
      fullWidth
    >
      <DialogContent>
        <ScreenStack rootLabel="Choix facture">
          <InvoiceSelect onSelect={onSelect} />
        </ScreenStack>
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceSelectDialog;
