import React from "react";
import { makeStyles, Theme, Container, List } from "@material-ui/core";
import TopProgress from "components/TopProgress";
import BankStatementRepository from "lib/db/BankStatementRepository";
import ListHeader from "components/ListHeader";
import { SORT_DIRECTION } from "lib/db/utils";
import BankOperationItem from "./BankOperationItem";
import { DbBankOperation } from "lib/db/Models";
import BankOperationFilters, {
  BankOperationFiltersModel,
} from "./BankOperationFilters";
import InvoiceRepository from "lib/db/InvoiceRepository";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function prepareData(
  data: DbBankOperation[],
  search: string
): DbBankOperation[] {
  return data.reduce<DbBankOperation[]>((prev, cur) => {
    if (
      search &&
      !(cur.label.toLowerCase().indexOf(search.toLowerCase()) > -1)
    ) {
      // Filtered
      return prev;
    }
    return [...prev, cur];
  }, []);
}

const BankOperations: React.FunctionComponent<{}> = () => {
  const [sort, setSort] = React.useState(SORT_DIRECTION.SORT_DESC);
  const [isSaving, setIsSaving] = React.useState(false);
  const [filter, setFilter] = React.useState<BankOperationFiltersModel>({
    from: undefined,
    to: undefined,
  });
  const [ops, isLoading, load] = BankStatementRepository.useOperations(
    sort,
    filter.from,
    filter.to
  );
  const [invoices, , loadInvoices] = InvoiceRepository.useMany(
    {},
    {},
    { loadNow: false, indexById: true }
  );
  const [search, setSearch] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    const invoiceIds = ops.map(v => v.invoiceId).filter(Boolean);
    if (invoiceIds.length) {
      loadInvoices({ _id: { $in: invoiceIds } });
    }
  }, [ops, loadInvoices]);

  const handleSort = (direction: SORT_DIRECTION) => {
    setSort(direction);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleChange = (op: DbBankOperation) => {
    setIsSaving(true);
    BankStatementRepository.linkInvoice(op.statementId, op.id, op.invoiceId)
      .then(r => {
        load();
      })
      .catch(console.error)
      .finally(() => setIsSaving(false));
  };

  const handleFilter = (value: BankOperationFiltersModel) => {
    setFilter(value);
  };

  const data = React.useMemo(() => {
    return prepareData(ops, search);
  }, [ops, search]);

  return (
    <div className={classes.root}>
      <TopProgress show={isLoading || isSaving} />
      <Container maxWidth="sm" disableGutters>
        <List dense>
          <ListHeader
            sort={sort}
            onSort={handleSort}
            search={search}
            onSearch={handleSearch}
            filter={filter}
            onFilter={handleFilter}
          >
            <BankOperationFilters />
          </ListHeader>
          {data.map(value => (
            <BankOperationItem
              key={`${value.statementId.toHexString()}/${value.id.toHexString()}`}
              value={value}
              onChange={handleChange}
              invoices={invoices}
            />
          ))}
        </List>
      </Container>
    </div>
  );
};

export default BankOperations;
