import { DbUser, DocumentId } from "./Models";
import Repository from "./Repository";
import { ensureObjectId, getDb } from "./utils";
import { Stitch } from "mongodb-stitch-browser-sdk";

class UserRepository extends Repository<DbUser> {
  constructor() {
    super("usersdata");
  }

  public save(user: DbUser) {
    const { password, ...data } = user;
    const email = data.email;
    if (user._id) {
      return super.save(data).then(user => {
        if (password) {
          return Stitch.defaultAppClient
            .callFunction("setPassword", [{ email, password }])
            .then(() => user);
        } else {
          return user;
        }
      });
    } else {
      return Stitch.defaultAppClient
        .callFunction("setPassword", [{ email, password }])
        .then(userId => super.save({ ...data, userId }));
    }
  }

  public delete(id: DocumentId) {
    id = ensureObjectId(id);
    return this.getCollection()
      .findOne({ _id: id })
      .then(user => {
        if (user) {
          return getDb().collection("users").deleteOne({ _id: user.userId });
        }
      })
      .then(() => {
        return super.delete(id);
      });
  }
}

export default new UserRepository();
