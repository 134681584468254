import { DbContact } from "../db/Models";
import espaceHtml from "escape-html";

export function contactAvatarText(contact: DbContact): string {
  let text = "";
  if (contact.lastname) {
    text += contact.lastname.substr(0, 1).toUpperCase();
  }
  if (contact.firstname) {
    text += contact.firstname.substr(0, 1).toUpperCase();
  }
  if (text.length < 2) {
    text += contact.company.substr(0, 2 - text.length).toUpperCase();
  }
  return text;
}

export function contactLabelText(contact: DbContact): string {
  let text = contact.company;
  if (contact.lastname || contact.firstname) {
    const lastname = contact.lastname || "";
    const firstname = contact.firstname || "";
    text += " — ";
    text += `${lastname} ${firstname}`.trim();
  }
  return text;
}

export function contactLabelHtml(contact: DbContact): string {
  const company = espaceHtml(contact.company);
  let html = `<strong>${company}</strong>`;
  if (contact.lastname || contact.firstname) {
    const lastname = espaceHtml(contact.lastname || "");
    const firstname = espaceHtml(contact.firstname || "");
    html += " — ";
    html += `${lastname} ${firstname}`.trim();
  }
  return html;
}
