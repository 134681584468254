import React from "react";
import {
  Container,
  makeStyles,
  Theme,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  CircularProgress,
  Box,
} from "@material-ui/core";
import {
  Link as DropBoxConnect,
  LinkOff as DropBoxDisconnect,
} from "@material-ui/icons";
import DropBox from "dropbox";
import queryString from "query-string";
import LoadingButton from "components/LoadingButton";
import OrgRepository from "lib/db/OrgRepository";
import { FormattedMessage } from "react-intl";
import useMessage from "lib/hooks/useMessage";

const DROPBOX_CLIENT_ID = "dun54sf4l0006hc";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  actions: {
    justifyContent: "end",
  },
}));

type LinkState = "loading" | "notconnected" | "connected" | "failed";

function checkLink(accessToken: string): Promise<string> {
  return new Promise<string>((res, rej) => {
    const dbx = new DropBox.Dropbox({ accessToken, fetch });
    dbx
      .usersGetCurrentAccount()
      .then((a) => {
        res(a.email);
      })
      .catch((err) => {
        console.log(err);
        rej(err);
      });
  });
}

const Settings: React.FunctionComponent<{}> = () => {
  const [linkState, setLinkState] = React.useState<LinkState>("loading");
  const [accountLabel, setAccountLabel] = React.useState("");
  const [org, , load] = OrgRepository.useMany();
  const [redirecting, setRedirecting] = React.useState(false);
  const [disconnecting, setDisconnecting] = React.useState(false);
  const t = useMessage();
  const classes = useStyles();

  const handleDropboxConnect = () => {
    setRedirecting(true);
    const dbx = new DropBox.Dropbox({ clientId: DROPBOX_CLIENT_ID, fetch });
    const url = dbx.getAuthenticationUrl(window.location.href);
    window.location.assign(url);
  };

  const handleDropboxDisconnect = () => {
    setDisconnecting(true);
    OrgRepository.save({ ...org[0], dropboxToken: undefined }).then(() => {
      setDisconnecting(false);
      load();
    });
  };

  React.useEffect(() => {
    if (org && org[0]) {
      const { access_token } = queryString.parse(window.location.hash);
      if (access_token) {
        checkLink(access_token)
          .then((name) => {
            setAccountLabel(name);
            setLinkState("connected");
            OrgRepository.save({ ...org[0], dropboxToken: access_token });
            window.location.hash = "";
          })
          .catch(() => {
            setLinkState("failed");
          });
      } else if (org[0].dropboxToken) {
        checkLink(org[0].dropboxToken)
          .then((name) => {
            setAccountLabel(name);
            setLinkState("connected");
          })
          .catch(() => {
            setLinkState("failed");
          });
      } else {
        setLinkState("notconnected");
      }
    }
  }, [org]);

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Card>
          <CardHeader title={t("settings.dropbox.title")} />
          <CardContent>
            <Box display="flex" justifyContent="center">
              {linkState === "loading" && <CircularProgress />}
              {linkState === "notconnected" && (
                <FormattedMessage id="settings.dropbox.notconnected" />
              )}
              {linkState === "connected" && (
                <FormattedMessage
                  id="settings.dropbox.connected"
                  values={{ accountLabel }}
                />
              )}
              {linkState === "failed" && (
                <div>
                  <p>
                    <FormattedMessage id="settings.dropbox.failed1" />
                  </p>
                  <p>
                    <FormattedMessage id="settings.dropbox.failed2" />
                  </p>
                </div>
              )}
            </Box>
          </CardContent>
          <CardActions className={classes.actions}>
            {(linkState === "loading" ||
              linkState === "connected" ||
              linkState === "failed") && (
              <LoadingButton
                color="secondary"
                variant="contained"
                disabled={linkState === "loading"}
                loading={disconnecting}
                onClick={handleDropboxDisconnect}
                endIcon={<DropBoxDisconnect />}
              >
                <FormattedMessage id="settings.disconnect" />
              </LoadingButton>
            )}
            {(linkState === "loading" || linkState === "notconnected") && (
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={linkState === "loading"}
                loading={redirecting}
                onClick={handleDropboxConnect}
                endIcon={<DropBoxConnect />}
              >
                <FormattedMessage id="settings.connect" />
              </LoadingButton>
            )}
          </CardActions>
        </Card>
      </Container>
    </div>
  );
};

export default Settings;
