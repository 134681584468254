import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ListItem, ListItemText, Divider } from "@material-ui/core";
import { DbBankStatement } from "lib/db/Models";

const date = new DateFnsUtils();

type onEditCallback = (id: string) => void;

interface Props {
  bankStatement: DbBankStatement;
  onEdit?: onEditCallback;
}

const BankStatementItem: React.FunctionComponent<Props> = ({
  bankStatement,
  onEdit,
}) => {
  const handleEdit = (id: string) => () => {
    if (onEdit) {
      onEdit(id);
    }
  };

  const id = bankStatement._id!.toHexString();

  return (
    <>
      <ListItem onClick={handleEdit(id)} button>
        <ListItemText
          primary={bankStatement.label}
          secondary={date.format(bankStatement.date, "dd/MM/yyyy")}
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default BankStatementItem;
