import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import frLocale from "date-fns/locale/fr";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import Home from "./screens/Home";
import AppBar from "./AppBar";
import Users from "./screens/User/Users";
import User from "./screens/User/User";
import Contacts from "./screens/Contact/Contacts";
import Contact from "./screens/Contact/Contact";
import Invoices from "./screens/Invoice/Invoices";
import Invoice from "./screens/Invoice/Invoice";
import BankStatements from "./screens/BankStatement/BankStatements";
import BankStatement from "./screens/BankStatement/BankStatement";
import BankOperations from "./screens/BankStatement/BankOperations";
import Assets from "./screens/Asset/Assets";
import Settings from "./screens/Settings";
import { StorageProvider } from "lib/storage/StorageContext";

const useStyles = makeStyles({
  content: {
    flexGrow: 1,
    display: "flex",
    minHeight: 0,
  },
});

const AppRouter: React.FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <StorageProvider>
        <Router>
          <AppBar />
          <div className={classes.content}>
            <Switch>
              <Route path="/customer-invoices">
                <Invoices supplier={false} />
              </Route>
              <Route path="/customer-invoice/:id?">
                <Invoice supplier={false} />
              </Route>
              <Route path="/supplier-invoices">
                <Invoices supplier={true} />
              </Route>
              <Route path="/supplier-invoice/:id?">
                <Invoice supplier={true} />
              </Route>
              <Route path="/contacts">
                <Contacts />
              </Route>
              <Route path="/contact/:id?">
                <Contact />
              </Route>
              <Route path="/bankstatements">
                <BankStatements />
              </Route>
              <Route path="/bankstatement/:id?">
                <BankStatement />
              </Route>
              <Route path="/bankops">
                <BankOperations />
              </Route>
              <Route path="/assets">
                <Assets />
              </Route>
              <Route path="/asset/:id?">
                <Assets edit={true} />
              </Route>
              <Route path="/users">
                <Users />
              </Route>
              <Route path="/user/:id?">
                <User />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </StorageProvider>
    </MuiPickersUtilsProvider>
  );
};

export default AppRouter;
