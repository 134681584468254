import React from "react";
import EditCard from "components/EditCard";
import { useHistory, useParams } from "react-router-dom";
import useMessage from "lib/hooks/useMessage";
import UserRepository from "lib/db/UserRepository";
import UserFields, { userFieldsValidate } from "./UserFields";
import { Stitch } from "mongodb-stitch-browser-sdk";

interface Params {
  id?: string;
}

const UserForm: React.FunctionComponent<{}> = () => {
  const [title, setTitle] = React.useState("Création utilisateur");
  const [user, isLoading, load] = UserRepository.useOne();
  const params = useParams<Params>();
  const history = useHistory();
  const t = useMessage();

  const handleCancel = () => {
    history.push("/users");
  };

  const handleSave = (values: any) => {
    return UserRepository.save(values).then(o => {
      history.push("/users");
    });
  };

  let handleDelete;
  if (
    user &&
    user._id &&
    Stitch.defaultAppClient.auth.user?.customData._id !== user._id.toHexString()
  ) {
    handleDelete = () => {
      return UserRepository.delete(user._id!).then(() =>
        history.push("/users")
      );
    };
  }

  React.useEffect(() => {
    if (params.id) {
      setTitle(t("users.edit"));
      load(params.id);
    } else {
      setTitle(t("users.create"));
      load();
    }
  }, [params.id, load, t]);

  return (
    <EditCard
      title={title}
      values={user || { level: "user" }}
      loading={isLoading}
      onValidate={userFieldsValidate(Boolean(user))}
      onCancel={handleCancel}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <UserFields edit={Boolean(user)} />
    </EditCard>
  );
};

export default UserForm;
