import React from "react";
import * as Yup from "yup";
import { TextField, KeyboardDatePicker, Autocomplete } from "mui-rff";
import { DbInvoice } from "lib/db/Models";
import { makeValidate, makeRequired } from "mui-rff";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { AddCircleOutline as AddUser } from "@material-ui/icons";
import ContactRepository from "lib/db/ContactRepository";
import { useForm } from "react-final-form";
import FileDropAdapter from "components/formAdapter/FileDropAdapter";
import useMessage from "lib/hooks/useMessage";
import { contactLabelHtml, contactLabelText } from "lib/utils/Contact";
import { useScreenStack } from "components/ScreenStack";
import ContactForm from "screens/Contact/ContactForm";
import { ObjectId } from "bson";
import LoadingOverlay from "components/LoadingOverlay";

const useStyles = makeStyles({
  contact: {
    display: "flex",
  },
  contactSelect: {
    flexGrow: 1,
  },
});

type FormModel = Omit<Partial<DbInvoice>, "contactId">;

const schema = Yup.object().shape<FormModel>({
  date: Yup.date().required(),
  folder: Yup.string(),
  total: Yup.number().positive().required(),
  paid: Yup.number().min(0),
  bankName: Yup.string(),
  bankStatementDate: Yup.date(),
});

export const invoiceFieldsValidate = makeValidate(schema);
const required = makeRequired(schema);

interface Props {
  contactLabel: string;
  parentLoading: boolean;
}

const InvoiceFields: React.FunctionComponent<Props> = ({
  contactLabel,
  parentLoading,
}) => {
  const [contacts, contactsLoading, loadCustomers] = ContactRepository.useMany(
    {},
    { company: 1, lastname: 1, firstname: 1 }
  );
  const t = useMessage();
  const classes = useStyles();
  const form = useForm();
  const stack = useScreenStack();

  const contactsOptions = React.useMemo(() => {
    return contacts.map(c => {
      return {
        value: c._id!.toHexString(),
        html: contactLabelHtml(c),
        label: contactLabelText(c),
      };
    });
  }, [contacts]);

  const handleUserAdded = (id?: ObjectId) => {
    if (id) {
      return loadCustomers().then(() => {
        form.mutators.setContactId(id.toHexString());
      });
    }
  };

  const handleAddUser = () => {
    stack.push(t(contactLabel), <ContactForm />, handleUserAdded);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            label={t("invoices.field.date")}
            name="date"
            required={required.date}
          />
        </Grid>
        <Grid xs={12} item>
          <TextField
            label={t("invoices.field.folder")}
            name="folder"
            required={required.folder}
            fullWidth
          />
        </Grid>
        <Grid xs={12} className={classes.contact} item>
          <LoadingOverlay
            loading={contactsLoading && !parentLoading}
            className={classes.contactSelect}
          >
            <Autocomplete
              label={t(contactLabel)}
              name="contactId"
              required={required.contactId}
              loading={contactsLoading}
              options={contactsOptions}
              getOptionValue={option => option.value}
              getOptionLabel={option => option.label}
              renderOption={option => (
                <span dangerouslySetInnerHTML={{ __html: option.html }} />
              )}
            />
          </LoadingOverlay>
          <IconButton onClick={handleAddUser}>
            <AddUser />
          </IconButton>
        </Grid>
        <Grid sm={4} xs={12} item>
          <TextField
            label={t("invoices.field.total")}
            name="total"
            required={required.total}
            fullWidth
          />
        </Grid>
        <Grid sm={4} xs={12} item>
          <TextField
            label={t("invoices.field.paid")}
            name="paid"
            required={required.paid}
            fullWidth
          />
        </Grid>
        <Grid sm={4} xs={12} item>
          <TextField
            label={t("invoices.field.rest")}
            name="rest"
            disabled
            fullWidth
          />
        </Grid>
        <Grid sm={6} xs={12} item>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            label={t("invoices.field.bankStatementDate")}
            name="bankStatementDate"
            required={required.bankStatementDate}
          />
        </Grid>
        <Grid sm={6} xs={12} item>
          <TextField
            label={t("invoices.field.bankName")}
            name="bankName"
            required={required.bankName}
            fullWidth
          />
        </Grid>
        <Grid xs={12} item>
          <FileDropAdapter label={t("invoices.field.file")} path="Invoices" name="files" />
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceFields;
