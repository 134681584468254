import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import useMessage from "lib/hooks/useMessage";
import { DbUser } from "lib/db/Models";

const useStyles = makeStyles({
  avatar: {
    color: blue[900],
    backgroundColor: blue[50],
  },
});

type onEditCallback = (id: DbUser) => void;

interface Props {
  user: DbUser;
  onEdit?: onEditCallback;
}

const UserItem: React.FunctionComponent<Props> = ({ user, onEdit }) => {
  const classes = useStyles();
  const t = useMessage();

  const handleEdit = () => {
    if (onEdit) {
      onEdit(user);
    }
  };

  const usernameParts = user.name.split(" ");
  const avatar = (
    <Avatar className={classes.avatar}>
      {usernameParts.length > 1
        ? usernameParts[0].substr(0, 1).toUpperCase() +
          usernameParts[1].substr(0, 1).toUpperCase()
        : user.name.substr(0, 2).toUpperCase()}
    </Avatar>
  );
  const primary = (
    <>
      <strong>{user.name}</strong> — {user.email}
    </>
  );
  const secondary = <>{t(`users.type.${user.level}`)}</>;

  return (
    <>
      <ListItem onClick={handleEdit} button>
        <ListItemAvatar>{avatar}</ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default UserItem;
