import React from "react";
import { Marker } from "react-mapbox-gl";
import { Props as MarkerProps } from "react-mapbox-gl/lib/marker";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  marker: {
    width: 30,
    height: 30,
    fontWeight: "bold",
    borderRadius: "50%",
    backgroundColor: "#1f9d1f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    border: "3px solid #1b871b",
    cursor: "pointer",
  },
});

export interface MapMarkerProps extends MarkerProps {
  count: number;
}

const ClusterMarker: React.FunctionComponent<MapMarkerProps> = ({
  count,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Marker className={classes.marker} {...rest}>
      <div>{count}</div>
    </Marker>
  );
};

export default ClusterMarker;
