import React from "react";
import {
  ArrowUpward as SortIcon,
  FilterList as FilterIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import {
  makeStyles,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
  TextField,
  InputAdornment,
  Typography,
  Popover,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import clsx from "clsx";
import { SORT_DIRECTION } from "lib/db/utils";
import { Form } from "react-final-form";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: grey[50],
    borderRadius: 5,
    marginBottom: theme.spacing(2),
  },
  filters: {
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.width("sm"),
  },
  arrow: {
    transition: theme.transitions.create(["opacity", "transform"], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  arrowDescending: { transform: "rotate(0deg)" },
  arrowAscending: {
    transform: "rotate(180deg)",
  },
  actions: {
    marginTop: theme.spacing(2),
    textAlign: "right",
  },
}));

type onSortCallback = (value: SORT_DIRECTION) => void;
type onSearchCallback = (value: string) => void;
type onFilterCallback<FilterModel> = (value: FilterModel) => void;

interface Props<FilterModel> {
  sort?: SORT_DIRECTION;
  onSort?: onSortCallback;
  search?: string;
  onSearch?: onSearchCallback;
  filter?: FilterModel;
  onFilter?: onFilterCallback<FilterModel>;
}

export default function ListHeader<FilterModel>(
  props: React.PropsWithChildren<Props<FilterModel>>
) {
  const { sort, onSort, search, onSearch, filter, onFilter, children } = props;
  const classes = useStyles();
  const [
    anchorFilterMenu,
    setAnchorFilterMenu,
  ] = React.useState<HTMLButtonElement | null>(null);
  const sortClass =
    sort === SORT_DIRECTION.SORT_ASC
      ? classes.arrowAscending
      : classes.arrowDescending;

  const handleSortClick = React.useCallback(() => {
    if (onSort) {
      onSort(
        sort === SORT_DIRECTION.SORT_ASC
          ? SORT_DIRECTION.SORT_DESC
          : SORT_DIRECTION.SORT_ASC
      );
    }
  }, [sort, onSort]);

  const handleSearchChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (onSearch) {
        onSearch(ev.target.value);
      }
    },
    [onSearch]
  );

  const handleFilterOpenClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorFilterMenu(event.currentTarget);
  };

  const handleFilterSubmit = (values: FilterModel) => {
    setAnchorFilterMenu(null);
    if (onFilter) {
      onFilter(values);
    }
  };

  return (
    <ListItem className={classes.root}>
      {sort && (
        <ListItemIcon>
          <IconButton
            onClick={handleSortClick}
            color="primary"
            size="small"
            edge="start"
          >
            <SortIcon className={clsx(sortClass, classes.arrow)} />
          </IconButton>
        </ListItemIcon>
      )}
      <ListItemText>
        <TextField
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body2" color="textSecondary">
                  <SearchIcon />
                </Typography>
              </InputAdornment>
            ),
          }}
          value={search}
          fullWidth
        />
      </ListItemText>
      {children && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={handleFilterOpenClick}
            color="primary"
            size="small"
            edge="end"
          >
            <FilterIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorFilterMenu)}
            anchorEl={anchorFilterMenu}
            onClose={() => setAnchorFilterMenu(null)}
            classes={{ paper: classes.filters }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Form
              onSubmit={handleFilterSubmit}
              initialValues={filter}
              validateOnBlur={true}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  {children}
                  <div className={classes.actions}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      type="submit"
                    >
                      <FormattedMessage id="apply" />
                    </Button>
                  </div>
                </form>
              )}
            </Form>
          </Popover>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
