import React from "react";
import { makeStyles, Theme, Container, Card } from "@material-ui/core";
import BankStatementForm from "./BankStatementForm";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
}));

const BankStatement: React.FunctionComponent<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Card>
          <BankStatementForm />
        </Card>
      </Container>
    </div>
  );
};

export default BankStatement;
