import React from "react";
import { ButtonProps, makeStyles, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: 4,
  },
});

interface Props extends ButtonProps {
  show: boolean;
}

const TopProgress: React.FunctionComponent<Props> = ({ show }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {show && <LinearProgress variant="query" />}
    </div>
  );
};

export default TopProgress;
