import React from "react";
import * as Yup from "yup";
import { TextField } from "mui-rff";
import { DbContact } from "lib/db/Models";
import { makeValidate, makeRequired } from "mui-rff";
import useMessage from "lib/hooks/useMessage";
import { Grid } from "@material-ui/core";

const schema = Yup.object().shape<DbContact>({
  company: Yup.string().required(),
  firstname: Yup.string(),
  lastname: Yup.string(),
  address: Yup.string(),
  city: Yup.string(),
  zipCode: Yup.string(),
});

export const contactFieldsValidate = makeValidate(schema);
const required = makeRequired(schema);

const ContactFields: React.FunctionComponent<{}> = () => {
  const t = useMessage();

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <TextField
          label={t("contacts.field.company")}
          name="company"
          required={required.company}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("contacts.field.firstname")}
          name="firstname"
          required={required.firstname}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("contacts.field.lastname")}
          name="lastname"
          required={required.lastname}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("contacts.field.address")}
          name="address"
          multiline
          rows="2"
          required={required.address}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("contacts.field.zipCode")}
          name="zipCode"
          required={required.zipCode}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("contacts.field.city")}
          name="city"
          required={required.city}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default ContactFields;
