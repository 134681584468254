import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";
import {
  DbInvoice,
  DbBankOperation,
} from "lib/db/Models";
import { Skeleton } from "@material-ui/lab";
import InvoiceSelectDialog from "../Invoice/InvoiceSelectDialog";
import { invoiceLabelHtml, invoiceTypeIcon } from "lib/utils/Invoice";

const date = new DateFnsUtils();

type onChangeCallback = (value: DbBankOperation) => void;

interface Props {
  value: DbBankOperation;
  onChange: onChangeCallback;
  invoices: KV<DbInvoice>;
}

const BankOperationItem: React.FunctionComponent<Props> = ({
  value,
  onChange,
  invoices,
}) => {
  const [showDialog, setShowDialog] = React.useState(false);

  const handleInvoiceSelect = (invoice: DbInvoice | undefined | null) => {
    if (invoice) {
      onChange({
        ...value,
        invoiceId: invoice._id,
      });
    } else if (invoice === null) {
      onChange({
        ...value,
        invoiceId: undefined,
      });
    }
    setShowDialog(false);
  };

  const primary = (
    <Box display="flex">
      <Box flexGrow="1">
        {date.format(value.date, "dd/MM/yyyy")} — <strong>{value.label}</strong>
      </Box>
      <Box>
        <Typography color="textSecondary" variant="body2">
          {value.amount.toFixed(2)}€
        </Typography>
      </Box>
    </Box>
  );

  let secondary;
  if (value.invoiceId) {
    if (invoices[value.invoiceId.toHexString()]) {
      const invoice = invoices[value.invoiceId.toHexString()];
      const Icon = invoiceTypeIcon(invoice);
      secondary = (
        <span>
          {<Icon fontSize="inherit" />} {invoiceLabelHtml(invoice)}
        </span>
      );
    } else {
      secondary = <Skeleton animation="wave" />;
    }
  }

  return (
    <>
      <ListItem onClick={() => setShowDialog(true)} button>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
      <Divider component="li" />
      {showDialog && (
        <InvoiceSelectDialog open={showDialog} onSelect={handleInvoiceSelect} />
      )}
    </>
  );
};

export default BankOperationItem;
