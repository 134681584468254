import React from "react";
import * as Yup from "yup";
import { TextField, KeyboardDatePicker } from "mui-rff";
import { makeValidate, makeRequired } from "mui-rff";
import { Grid, List } from "@material-ui/core";
import {
  DbBankStatement,
  DbBankStatementEntry,
  DbInvoice,
} from "lib/db/Models";
import useMessage from "lib/hooks/useMessage";
import CsvFileDropAdapter from "components/formAdapter/CsvFileDropAdapter";
import BankStatementEntryItem from "./BankStatementEntryItem";

const entrySchema = Yup.object().shape<DbBankStatementEntry>({
  date: Yup.date().required(),
  label: Yup.string().required(),
  amount: Yup.number().required(),
});

const schema = Yup.object().shape<DbBankStatement>({
  label: Yup.string().required(),
  date: Yup.date().required(),
  entries: Yup.array().of(entrySchema),
});

export const bankStatementFieldsValidate = makeValidate(schema);
const required = makeRequired(schema);

interface Props {
  invoices: KV<DbInvoice>;
}

const BankStatementFields: React.FunctionComponent<Props> = ({ invoices }) => {
  const t = useMessage();

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12} item>
          <TextField
            label={t("bankstatements.field.label")}
            name="label"
            required={required.label}
            fullWidth
          />
        </Grid>
        <Grid xs={12} item>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            label={t("bankstatements.field.date")}
            name="date"
            required={required.date}
          />
        </Grid>
        <Grid xs={12} item>
          <CsvFileDropAdapter<DbBankStatementEntry>
            name="entries"
            label={t("bankstatements.field.entries")}
            hint={t("bankstatements.field.entrieshint")}
            itemsComponent={<List dense />}
            renderItem={({ value, onChange }) => (
              <BankStatementEntryItem
                value={value}
                onChange={onChange}
                invoices={invoices}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BankStatementFields;
