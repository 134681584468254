import React from "react";
import {
  makeStyles,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";
import { StorageFile, getFileExtension } from "lib/Storage";
import { StorageContext } from "lib/storage/StorageContext";
import { Alert } from "@material-ui/lab";
import useMessage from "lib/hooks/useMessage";

const useStyles = makeStyles((theme: Theme) => {
  const color = theme.palette.grey[600];
  return {
    root: {
      overflow: "hidden",
      height: "100%",
    },
    nowrap: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    toolbar: {
      background: color,
      color: theme.palette.getContrastText(color),
      minHeight: "auto",
    },
    title: {
      fontSize: "1rem",
    },
    alert: {
      minWidth: 350,
    },
  };
});

type onCloseCallback = () => void;

interface Props {
  file: StorageFile;
  onClose: onCloseCallback;
}

const PreviewPanel: React.FunctionComponent<Props> = ({ file, onClose }) => {
  const [url, setUrl] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const storageAdapter = React.useContext(StorageContext).adapter;
  const t = useMessage();
  const classes = useStyles();

  React.useEffect(() => {
    setUrl(undefined);
    setError(undefined);
    if (storageAdapter) {
      storageAdapter
        .previewUrl(file)
        .then(setUrl)
        .catch((err) => {
          console.error(err);
          setUrl(undefined);
          setError(
            t("components.previewpanel.formatnotsupported", {
              format: getFileExtension(file.name).toUpperCase(),
            })
          );
        });
    }
  }, [file, storageAdapter, t]);

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Box>
        <Toolbar className={classes.toolbar} variant="dense">
          <Typography
            variant="h6"
            className={clsx(classes.nowrap, classes.title)}
            color="inherit"
          >
            {file.name}
          </Typography>
          <Box flexGrow={1}></Box>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {url && (
          <object type="application/pdf" data={url} width="100%" height="100%">
            {file.name}
          </object>
        )}
        {!url && (
          <>
            {error ? (
              <Alert className={classes.alert} severity="error">
                {error}
              </Alert>
            ) : (
              <CircularProgress />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PreviewPanel;
