import React from "react";
import * as Yup from "yup";
import { TextField, Select } from "mui-rff";
import { DbUser } from "lib/db/Models";
import { makeValidate, makeRequired } from "mui-rff";
import useMessage from "lib/hooks/useMessage";
import { Grid, MenuItem } from "@material-ui/core";

const commonSchema = {
  name: Yup.string().required().min(3),
  email: Yup.string().required().email(),
  level: Yup.mixed().oneOf(["user", "admin"]).required(),
};

const createSchema = Yup.object().shape<Omit<DbUser, "userId">>({
  ...commonSchema,
  password: Yup.string().required().min(8),
});

const editSchema = Yup.object().shape<Omit<DbUser, "userId">>({
  ...commonSchema,
  password: Yup.string().min(8),
});

export const userFieldsValidate = (edit: boolean) => {
  return makeValidate(edit ? editSchema : createSchema);
};
export const userFieldsRequired = (edit: boolean) => {
  return makeRequired(edit ? editSchema : createSchema);
};

interface Props {
  edit: boolean;
}

const UserFields: React.FunctionComponent<Props> = ({ edit }) => {
  const t = useMessage();
  const required = userFieldsRequired(edit);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <TextField
          label={t("users.field.name")}
          name="name"
          required={required.name}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("email")}
          name="email"
          required={required.email}
          disabled={edit}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={edit ? t("users.updatepassword") : t("password")}
          name="password"
          required={required.password}
          autoComplete="off"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <Select label={t("users.field.type")} name="level">
          <MenuItem value="user">{t("users.type.user")}</MenuItem>
          <MenuItem value="admin">{t("users.type.admin")}</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default UserFields;
