import { DbBankStatement, DbBankOperation } from "./Models";
import Repository, { AggregateHookReturn } from "./Repository";
import { SORT_DIRECTION } from "./utils";
import { ObjectId } from "bson";

const operationsPipeline = [
  // {
  //   $match: {
  //     "entries.date": { $gt: new Date("2020-04-05T22:00:00.000+00:00") },
  //   },
  // },
  {
    $unwind: {
      path: "$entries",
      preserveNullAndEmptyArrays: false,
    },
  },
  {
    $project: {
      _id: 0,
      statementId: "$_id",
      statementLabel: "$label",
      id: "$entries._id",
      date: "$entries.date",
      label: "$entries.label",
      amount: "$entries.amount",
      invoiceId: "$entries.invoiceId",
    },
  },
  // {
  //   $match: {
  //     date: { $gt: new Date("2020-04-05T22:00:00.000+00:00") },
  //   },
  // },
];

function prepareOperationsPipeline(
  sort: SORT_DIRECTION,
  from?: Date,
  to?: Date
) {
  const pre: object[] = [];
  const post: object[] = [];
  if (from && to) {
    pre.push({
      $match: {
        "entries.date": { $gte: from, $lte: to },
      },
    });
    post.push({
      $match: {
        date: { $gte: from, $lte: to },
      },
    });
  } else if (from) {
    pre.push({
      $match: {
        "entries.date": { $gte: from },
      },
    });
    post.push({
      $match: {
        date: { $gte: from },
      },
    });
  } else if (to) {
    pre.push({
      $match: {
        "entries.date": { $lte: to },
      },
    });
    post.push({
      $match: {
        date: { $lte: to },
      },
    });
  }
  return [
    ...pre,
    ...operationsPipeline,
    ...post,
    {
      $sort: {
        date: sort,
      },
    },
  ];
}

class DbBankStatementRepository extends Repository<DbBankStatement> {
  constructor() {
    super("bankstatements");
  }

  public save(obj: DbBankStatement) {
    if (obj.entries) {
      obj.entries = obj.entries.map(e => {
        if (!e._id) {
          e._id = new ObjectId();
        }
        delete e.tmpProps;
        return e;
      });
    }
    return super.save(obj);
  }

  public useOperations(
    sort: SORT_DIRECTION,
    from?: Date,
    to?: Date
  ): AggregateHookReturn<DbBankOperation[]> {
    return this.useAggregate<DbBankOperation>(
      prepareOperationsPipeline(sort, from, to)
    );
  }

  public linkInvoice(
    statementId: ObjectId,
    entryId: ObjectId,
    invoiceId?: ObjectId
  ) {
    return this.getCollection().updateOne(
      { _id: statementId, "entries._id": entryId },
      { $set: { "entries.$.invoiceId": invoiceId } }
    );
  }
}

export default new DbBankStatementRepository();
