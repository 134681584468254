import * as Icons from "@material-ui/icons";

interface AssetType {
  icon: Icons.SvgIconComponent;
}

const assetTypes: { [key: string]: AssetType } = {
  apartment: { icon: Icons.Apartment },
  house: { icon: Icons.Home },
  store: { icon: Icons.Store },
  activity: { icon: Icons.LocalShipping },
  office: { icon: Icons.Work },
  building: { icon: Icons.LocationCity },
  parking: {icon : Icons.LocalParking},
  terrain: {icon : Icons.Terrain},
  terrainnobuild: {icon : Icons.TerrainSharp},
};

export default assetTypes;
