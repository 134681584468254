import React from "react";
import { PrimitiveType } from "intl-messageformat";
import { useIntl } from "react-intl";

const useMessage = () => {
  const intl = useIntl();
  return React.useCallback(
    (id: string, values?: Record<string, PrimitiveType>): string => {
      return intl.formatMessage({ id }, values);
    },
    [intl]
  );
};

export default useMessage;
