import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import Dropzone from "react-dropzone";
import { makeStyles, Theme, Button, Box } from "@material-ui/core";
import { CloudUpload as UploadIcon } from "@material-ui/icons";

const dateUtils = new DateFnsUtils();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  dropzone: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    border: "1px solid gray",
    borderRadius: 5,
  },
  info: {
    color: "gray",
  },
}));

type onChangeCallback = (value: any[]) => void;

export type CsvFileDropOnItemChangeCallback<T> = (value: T) => void;

export interface CsvFileDropRenderItem<T> {
  value: T;
  onChange: CsvFileDropOnItemChangeCallback<T>;
}

interface Props<T> {
  value: T[];
  onChange: onChangeCallback;
  hint?: string;
  itemsComponent: React.ReactElement;
  renderItem: (options: CsvFileDropRenderItem<T>) => React.ReactNode;
}

function parseFile(file: File): Promise<object[]> {
  return new Promise<object[]>((res, rej) => {
    if (file.size > 1024 * 1000) {
      // Max 1MB
      rej("Ignore file size > 1MB");
      return;
    }
    var reader = new FileReader();
    reader.onload = function () {
      if (!reader.result) {
        rej("Read file error");
        return;
      }
      const lines = reader.result.toString().split(/\r?\n/);
      const data = lines
        .map(l => {
          if (l.startsWith(" ")) {
            const values = l.trim().split("\t");
            if (values.length > 3) {
              const date = dateUtils.parse(values.shift() || "", "dd/MM/yyyy");
              const amount = parseFloat(
                (values.shift() || "").replace(",", ".")
              );
              return {
                date,
                amount,
                label: values.filter(Boolean).join(" ").trim(),
              };
            }
          }
          return undefined;
        })
        .filter(Boolean) as object[];
      res(data);
    };
    reader.readAsText(file);
  });
}

export default function CsvFileDrop<T>(props: Props<T>) {
  const { value, onChange, hint, renderItem, itemsComponent } = props;
  const classes = useStyles();

  const handleDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const data = await parseFile(file);
    onChange(data);
  };

  const handleItemChange = (index: number) => (item: T) => {
    if (value[index]) {
      const newvalue = [...value];
      newvalue[index] = item;
      onChange(newvalue);
    }
  };

  if (Array.isArray(value) && value.length > 0) {
    return (
      <div className={classes.root}>
        {React.cloneElement(itemsComponent, {
          children: value.map((value, index) => (
            <React.Fragment key={index}>
              {renderItem({ value, onChange: handleItemChange(index) })}
            </React.Fragment>
          )),
        })}
        <Box textAlign="right">
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() => onChange([])}
          >
            Effacer les opérations
          </Button>
        </Box>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classes.dropzone}>
              <>
                <input {...getInputProps()} />
                <p>
                  <UploadIcon fontSize="large" color="action" />
                </p>
                {hint && <p className={classes.info}>{hint}</p>}
              </>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
