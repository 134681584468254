import React from "react";
import {
  makeStyles,
  Theme,
  Fab,
  Typography,
  IconButton,
  Box,
  List,
  Dialog,
  Slide,
} from "@material-ui/core";
import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons";
import useMessage from "lib/hooks/useMessage";
import { GeoLocation } from "lib/db/Models";
import { Alert } from "@material-ui/lab";
import AssetForm from "./AssetForm";
import { useHistory } from "react-router-dom";
import AssetRepository from "lib/db/AssetRepository";
import TopProgress from "components/TopProgress";
import { StorageFile } from "lib/Storage";
import { TransitionProps } from "@material-ui/core/transitions";
import PreviewPanel from "components/PreviewPanel";
import AssetItem from "./AssetItem";

const useStyles = makeStyles((theme: Theme) => ({
  part: {
    margin: theme.spacing(2),
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(2),
    zIndex: 1000,
  },
}));

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  location: GeoLocation;
  address?: string;
  edit?: boolean;
  onClose: () => void;
  onAssetSaved: () => void;
}

const AssetsSideBar: React.FunctionComponent<Props> = ({
  location,
  address,
  edit,
  onClose,
  onAssetSaved,
}) => {
  const [file, setFile] = React.useState<StorageFile>();
  const [assets, isLoading, load] = AssetRepository.useMany(
    {
      "location.coordinates": location,
    },
    { valueDate: -1 }
  );
  const t = useMessage();
  const history = useHistory();
  const classes = useStyles();

  const handleAdd = () => {
    history.push("/asset");
  };

  const handleEdit = (id?: string) => {
    history.push(`/asset/${id}`);
  };

  const handleAssetSaved = () => {
    onAssetSaved();
    load();
  };

  const handleClose = () => {
    onClose();
    history.push("/assets");
  };

  const handleFileSelect = (file: StorageFile) => {
    setFile(file);
  };

  const handlePreviewClose = () => {
    setFile(undefined);
  };
  // console.log(onAssetSaved());
  return (
    <>
      <Box className={classes.part} display="flex">
        <Box flexGrow="1">
          <Typography variant="h5" component="h2">
            {address}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      {edit && (
        <AssetForm
          address={address}
          location={location}
          onSaved={handleAssetSaved}
          onFileSelect={handleFileSelect}
        />
      )}
      {!edit && (
        <>
          <TopProgress show={isLoading} />
          {!isLoading && assets.length === 0 && (
            <Alert severity="info" className={classes.part}>
              Aucun bien à cette adresse
            </Alert>
          )}
          <List dense>
            {assets.map(asset => (
              <AssetItem
                key={asset._id?.toHexString()}
                asset={asset}
                onEdit={handleEdit}
              />
            ))}
          </List>
          <Fab
            aria-label={t("assets.add")}
            className={classes.fab}
            color="primary"
            onClick={handleAdd}
          >
            <AddIcon />
          </Fab>
        </>
      )}
      <Dialog
        open={Boolean(file)}
        TransitionComponent={DialogTransition}
        fullScreen
      >
        {file && <PreviewPanel file={file} onClose={handlePreviewClose} />}
      </Dialog>
    </>
  );
};

export default AssetsSideBar;
