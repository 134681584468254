import DateFnsUtils from "@date-io/date-fns";
import { DbInvoice } from "./Models";
import { ensureObjectId } from "./utils";
import { getNextIncrement } from "./IncrementRepository";
import Repository from "./Repository";

class InvoiceRepository extends Repository<DbInvoice> {
  constructor() {
    super("invoices");
  }

  public save(obj: DbInvoice) {
    const date = new DateFnsUtils();
    const refPrefix = date.format(obj.date, "yy-MM");

    obj.isPaid = obj.paid >= obj.total;
    obj.contactId = ensureObjectId(obj.contactId);
    if (!obj.ref || obj.ref.substr(0, 5) !== refPrefix) {
      // If new record or date change we need to change prefix
      const type = obj.supplier ? "supplier" : "customer";
      return getNextIncrement(`invoices-${refPrefix}-${type}`).then(value => {
        obj.ref = `${refPrefix}-${(value + "").padStart(3, "0")}`;
        return super.save(obj);
      });
    } else {
      return super.save(obj);
    }
  }
}

// export default { ...repo, save };
export default new InvoiceRepository();
