import React from "react";
import {
  Button,
  CircularProgress,
  ButtonProps,
} from "@material-ui/core";

interface Props extends ButtonProps {
  loading: boolean;
}

const LoadingButton: React.FunctionComponent<Props> = ({
  loading,
  disabled,
  children,
  endIcon,
  ...rest
}) => {
  if (loading) {
    endIcon = <CircularProgress size={16} />;
  }
  return (
    <Button disabled={loading || disabled} endIcon={endIcon} {...rest}>
      {children}
    </Button>
  );
};

export default LoadingButton;
