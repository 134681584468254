import React from "react";
import LoadingOverlay from "components/LoadingOverlay";
import {
  Button,
  CardHeader,
  CardContent,
  CardActions,
  makeStyles,
  IconButton,
  Box,
  Slide,
} from "@material-ui/core";
import { Save as SaveIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Form } from "react-final-form";
import LoadingButton from "./LoadingButton";
import { Alert } from "@material-ui/lab";
import { Decorator, Mutator } from "final-form";
import { FormattedMessage } from "react-intl";

type onCancelCallback = (reload: boolean) => void;
type onSaveCallback = (values: any) => Promise<any>;
type onValidateCallback = (values: any) => Promise<any>;
type onDeleteCallback = () => Promise<any>;

const useStyles = makeStyles({
  header: {
    paddingBottom: 0,
  },
  actions: {
    minHeight: 48,
  },
});

interface Props {
  title: string;
  values: object | null;
  loading?: boolean;
  decorators?: Decorator[];
  mutators?: { [key: string]: Mutator };
  onValidate?: onValidateCallback;
  onCancel?: onCancelCallback;
  onSave?: onSaveCallback;
  onDelete?: onDeleteCallback;
}

const EditCard: React.FunctionComponent<Props> = ({
  title,
  values,
  loading,
  decorators,
  mutators,
  onValidate,
  onCancel,
  onSave,
  onDelete,
  children,
}) => {
  const [error, setError] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const classes = useStyles();

  const handleCancel = () => {
    if (onCancel) {
      onCancel(false);
    }
  };

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleSubmit = (values: object) => {
    if (onSave && onValidate) {
      return onValidate(values).then(errors => {
        if (errors && Object.values(errors).length > 0) {
          return errors;
        }
        return onSave(values).catch(err => {
          if (err && typeof err === "object" && err.message === "Not found") {
            setError(true);
          }
        });
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={values || {}}
      validateOnBlur={true}
      decorators={decorators}
      mutators={mutators}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CardHeader className={classes.header} title={title} />
          <CardContent>
            <LoadingOverlay loading={loading}>{children}</LoadingOverlay>
          </CardContent>
          {error && (
            <CardContent>
              <Alert severity="error">
                <FormattedMessage id="components.edit.error1" />
                <br />
                <FormattedMessage id="components.edit.error2" />
              </Alert>
            </CardContent>
          )}
          {showConfirm && (
            <Slide direction="left" in={true}>
              <CardActions className={classes.actions}>
                <Box flexGrow={1} textAlign="right">
                  <strong>
                    <FormattedMessage id="components.edit.confirmdelete" />
                  </strong>
                </Box>
                <Button onClick={handleConfirmDelete} color="secondary">
                  <FormattedMessage id="yes" />
                </Button>
                <Button
                  onClick={() => setShowConfirm(false)}
                  variant="contained"
                  color="primary"
                >
                  <FormattedMessage id="no" />
                </Button>
              </CardActions>
            </Slide>
          )}
          {!showConfirm && (
            <Slide direction="left" in={true}>
              <CardActions className={classes.actions}>
                <Box flexGrow={1}></Box>
                {onDelete && (
                  <IconButton
                    onClick={handleDelete}
                    disabled={submitting || loading}
                    color="secondary"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                {onCancel && (
                  <Button
                    onClick={handleCancel}
                    disabled={submitting || loading}
                  >
                    <FormattedMessage id="cancel" />
                  </Button>
                )}
                {onSave && (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={submitting}
                    disabled={loading}
                    endIcon={<SaveIcon />}
                  >
                    <FormattedMessage id="save" />
                  </LoadingButton>
                )}
              </CardActions>
            </Slide>
          )}
        </form>
      )}
    </Form>
  );
};

export default EditCard;
