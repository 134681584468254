import React from "react";
import BankStatementRepository from "lib/db/BankStatementRepository";
import EditCard from "components/EditCard";
import { useHistory, useParams } from "react-router-dom";
import BankStatementFields, {
  bankStatementFieldsValidate,
} from "./BankStatementFields";
import useMessage from "lib/hooks/useMessage";
import InvoiceRepository from "lib/db/InvoiceRepository";

interface Params {
  id?: string;
}

const BankStatementForm: React.FunctionComponent<{}> = () => {
  const [title, setTitle] = React.useState("Création relevé bancaire");
  const [bankStatement, isLoading, load] = BankStatementRepository.useOne();
  const [invoices, , loadInvoices] = InvoiceRepository.useMany(
    {},
    {},
    { loadNow: false, indexById: true }
  );
  const params = useParams<Params>();
  const history = useHistory();
  const t = useMessage();

  React.useEffect(() => {
    if (bankStatement && bankStatement.entries) {
      const invoiceIds = bankStatement.entries
        .map(v => v.invoiceId)
        .filter(Boolean);
      loadInvoices({ _id: { $in: invoiceIds } });
    }
  }, [bankStatement, loadInvoices]);

  const handleCancel = () => {
    history.push("/bankstatements");
  };

  const handleSave = (values: any) => {
    return BankStatementRepository.save({ ...values }).then(() => {
      history.push("/bankstatements");
    });
  };

  const handleDelete = () => {
    if (bankStatement && bankStatement._id) {
      return BankStatementRepository.delete(bankStatement._id).then(() =>
        history.push("/bankstatements")
      );
    } else {
      return Promise.resolve();
    }
  };

  React.useEffect(() => {
    if (params.id) {
      setTitle(t("bankstatements.edit"));
      load(params.id);
    } else {
      setTitle(t("bankstatements.create"));
      load();
    }
  }, [params.id, load, t]);

  return (
    <EditCard
      title={title}
      values={bankStatement}
      loading={isLoading}
      onValidate={bankStatementFieldsValidate}
      onCancel={handleCancel}
      onSave={handleSave}
      onDelete={bankStatement ? handleDelete : undefined}
    >
      <BankStatementFields invoices={invoices} />
    </EditCard>
  );
};

export default BankStatementForm;
