import React from "react";
import { Field } from "react-final-form";
import { FormControl, InputLabel } from "@material-ui/core";
import CsvFileDrop, { CsvFileDropRenderItem } from "./CsvFileDrop";

interface CsvFileDropProps<T = any> {
  name: string;
  label: string;
  hint?: string;
  itemsComponent: React.ReactElement;
  renderItem: (options: CsvFileDropRenderItem<T>) => React.ReactNode;
}

export default function CsvFileDropAdapter<T = any>(
  props: CsvFileDropProps<T>
) {
  const { name, label, ...rest } = props;
  return (
    <Field
      name={name}
      render={({ input }) => (
        <FormControl fullWidth>
          <InputLabel shrink={true}>{label}</InputLabel>
          <CsvFileDrop<T>
            value={input.value}
            onChange={input.onChange}
            {...rest}
          />
        </FormControl>
      )}
    />
  );
}
