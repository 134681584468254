import useUploadQueue from "./storage/useUploadQueue";

export type onProgressCallback = (value: number) => void;
export type onUploadCallback = (file: StorageFile) => void;

export interface StorageFile {
  name: string;
  path: string;
}

export interface StorageInterface {
  upload(
    file: File,
    path: string,
    progress: onProgressCallback
  ): Promise<string>;
  previewUrl(file: StorageFile): Promise<string>;
}

export function getFileExtension(name: string): string {
  const i = name.lastIndexOf(".");
  return i > -1 ? name.substr(i + 1).toLowerCase() : "";
}

export function getFileBasename(name: string): string {
  const i = name.lastIndexOf(".");
  return i > -1 ? name.substr(0, i) : name;
}

export { useUploadQueue };
