import React from "react";
import {
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@material-ui/core";
import {
  CloudDownload as DownloadIcon
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import assetTypes from "./assetTypes";
import { DbAsset } from "lib/db/Models";
import useMessage from "lib/hooks/useMessage";
import { useDropboxDownloader } from "lib/hooks/useDropboxDownloader";

const date = new DateFnsUtils();

interface Props {
  asset: DbAsset;
  onEdit: (id?: string) => void;
}

const AssetItem: React.FC<Props> = ({ asset, onEdit }) => {
  const t = useMessage();
  const Icon = assetTypes[asset.type]?.icon;

  const handleEdit = (id?: string) => () => {
    onEdit(id);
  };

  const secondary = [
    t("assets.purposeshort." + asset.purpose),
    asset.value + " €",
    asset.valueDate
      ? date.format(date.parse(asset.valueDate, "yyyy-MM-dd"), "dd/MM/yyyy")
      : "",
  ].filter(Boolean);

  const handleDownload = useDropboxDownloader();

  return (
    <>
      <ListItem onClick={handleEdit(asset._id?.toHexString())} button>
        {Icon && (
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: "#3f51b5" }}>
              <Icon />
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText primary={asset.label} secondary={secondary.join(" - ")} />
        {asset.files.length > 0 && (

          <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation()
                  asset.files.forEach(file =>{
                    handleDownload(file)();
                  })
                }}
                edge="end"
                aria-label="download"
              >
                <DownloadIcon />
            </IconButton>
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default AssetItem;
