import React/*, { useState }*/from "react";
import * as Yup from "yup";
import { TextField, Select } from "mui-rff";
import { DbAsset } from "lib/db/Models";
import { makeValidate, makeRequired } from "mui-rff";
import useMessage from "lib/hooks/useMessage";
import {
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import assetTypes from "./assetTypes";
import assetNewTypes from "./assetNewTypes";
import assetPurposes from "./assetPurposes";
import { useField } from "react-final-form";
import FileDropAdapter from "components/formAdapter/FileDropAdapter";
import { StorageFile } from "lib/Storage";
import GeocodeField from "./GeocodeField";

const useStyles = makeStyles((theme: Theme) => ({
  typeIcon: {
    color: "#4e4e4e",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    verticalAlign: "bottom",
  },
}));

type FormAsset = Omit<DbAsset, "location"> & {
  longitude: number;
  latitude: number;
  addressSearch: object;
};

const schema = Yup.object().shape<FormAsset>({
  label: Yup.string().required("Est un champ requis"),
  address: Yup.string(),
  addressSearch: Yup.mixed().when("address", {
    is: v => !Boolean(v),
    then: Yup.string().required("Est un champ requis"),
  }),
  longitude: Yup.number().required("Est un champ requis"),
  latitude: Yup.number().required("Est un champ requis"),
  type: Yup.string().required("Est un champ requis"),
  purpose: Yup.string().required("Est un champ requis"),
  area: Yup.number().required("Est un champ requis"),
  value: Yup.number().required("Est un champ requis"),
  date: Yup.string().required("Est un champ requis"),
  valueDate: Yup.string(),
  comments: Yup.string(),
});

export const assetFieldsValidate = makeValidate(schema);
const required = makeRequired(schema);

interface Props {
  onFileSelect: (file: StorageFile) => void;
}

const AssetFields: React.FunctionComponent<Props> = ({ onFileSelect }) => {
  const t = useMessage();
  const classes = useStyles();
  const purposeField = useField("purpose");
  const addressField = useField("address");
  const valueLabel =
    purposeField.input.value === "locative"
      ? "assets.field.valuelocative"
      : "assets.field.value";
  const valueDateLabel =
    purposeField.input.value === "locative"
      ? "assets.field.valuedatelocative"
      : "assets.field.valuedate";

  const handleFileSelect = (file: StorageFile) => {
    onFileSelect(file);
  };

  interface AssetType {
    icon: Icons.SvgIconComponent;
  }

  const renderMenuItems = (types : { [key: string]: AssetType }, name : string) => {
    // console.log(types);
    return Object.entries(types)
      .map(([key, value]) => ({ 
        key, 
        label: t(`assets.${name}.${key}`), 
        Icon: value.icon // Capitalize Icon
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ key, Icon, label }) => (
        <MenuItem key={key} value={key}>
          <Icon className={classes.typeIcon} />
          {label}
        </MenuItem>
      ));
  };
  
  // const [otherTypes, setOtherTypes] = useState(false);
  // const handleOtherTypesClick = () => {
  //   setOtherTypes(true); // Active l'affichage de la liste supplémentaire
  //   console.log(otherTypes);
  // };
  // const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const value = event.target.value as string; // Cast 'unknown' to 'string'
  //   console.log(value);
  //   if (value === "other") {
  //     setOtherTypes(true);
  //   }
  // };  
  const customSTyleOtherButton = {
    backgroundColor : 'rgb(243, 242, 242)',
    borderTop: '1px solid grey',
    PointerEvents: 'none',
    cursor: 'default'
  }
  const renderAllMenuItems = () => {
    let items = renderMenuItems(assetTypes, "type");
    // console.log("OTHERTYPE : "+ otherTypes);
    items.push(
      <MenuItem key="other" style={customSTyleOtherButton} disabled >
        Autre
      </MenuItem>);

    items = items.concat(renderMenuItems(assetNewTypes, "newtype"));
    // if (!otherTypes) {
    // } else {
    //   console.log("GENERATE OTHER TYPES");
    // }

    return items;
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <TextField
          label={t("assets.field.label")}
          name="label"
          required={required.label}
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        {addressField.input.value ? (
          <TextField
            label={t("assets.field.address")}
            name="address"
            required={required.address}
            disabled
            multiline
            fullWidth
          />
        ) : (
          <GeocodeField
            name="addressSearch"
            label={t("assets.field.address")}
            required={required.address}
          />
        )}
      </Grid>
      <Grid xs={12} item>
      {/* <Select
        label={t("assets.field.type")}
        required={required.type}
        name="type"
      >
        {Object.entries(assetTypes)
          .map(([key, value]) => ({ 
            key, 
            label: t(`assets.type.${key}`), 
            Icon: value.icon // Capitalize Icon
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(({ key, Icon, label }) => (
            <MenuItem key={key} value={key}>
              <Icon className={classes.typeIcon} />
              {label}
            </MenuItem>
          ))}
      </Select> */}
        <Select label={t("assets.field.type")} required={required.type} name="type">
          {renderAllMenuItems()}
        </Select>
      </Grid>
      <Grid xs={12} item>
        <Select
          label={t("assets.field.purpose")}
          required={required.purpose}
          name="purpose"
          // onChange={handleSelectChange}
        >
          {assetPurposes.map(v => (
            <MenuItem key={v} value={v}>
              {t(`assets.purpose.${v}`)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid xs={6} item>
        <TextField
          label={t("assets.field.area")}
          name="area"
          InputProps={{
            endAdornment: <InputAdornment position="end">m²</InputAdornment>,
          }}
          required={required.area}
          type="number"
          fullWidth
        />
      </Grid>
      <Grid xs={6} item>
        <TextField
          label={t(valueLabel)}
          name="value"
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          required={required.value}
          type="number"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t(valueDateLabel)}
          name="valueDate"
          required={required.valueDate}
          InputLabelProps={{ shrink: true }}
          type="date"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <FileDropAdapter
          label={t("assets.field.files")}
          name="files"
          onSelect={handleFileSelect}
          path="Références"
          multiple
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("assets.field.comments")}
          name="comments"
          required={required.comments}
          fullWidth
          multiline
          rows={3}
          rowsMax={10}
          InputProps={{
            style: { fontSize: 14 }
          }}
        />
      </Grid>
      <Grid xs={12} item>
        <TextField
          label={t("assets.field.date")}
          name="date"
          required={required.date}
          InputLabelProps={{ shrink: true }}
          type="date"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default AssetFields;
