import React from "react";
import { makeStyles, Box, Theme, Slide, Dialog } from "@material-ui/core";
import clsx from "clsx";
import { StorageFile } from "lib/Storage";

import PreviewPanel from "./PreviewPanel";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    transition: "width 0.5s ease-out",
    width: "100%",
    "&.previewopen": {
      width: "50%",
    },
  },
  panel: {
    transition: "width 0.5s ease-out",
    width: "0%",
    "&.previewopen": {
      width: "50%",
    },
  },
}));

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type onCloseCallback = () => void;

interface Props {
  className?: string;
  file?: StorageFile;
  previewDialog?: boolean;
  onClose: onCloseCallback;
}

const PageWithPreview: React.FunctionComponent<Props> = ({
  className,
  file,
  previewDialog,
  onClose,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box className={className} display="flex">
      <Box
        className={clsx(
          classes.content,
          file && !previewDialog ? "previewopen" : null
        )}
        position="relative"
      >
        {children}
      </Box>
      <Box
        className={clsx(
          classes.panel,
          file && !previewDialog ? "previewopen" : null
        )}
      >
        {file && <PreviewPanel file={file} onClose={onClose} />}
      </Box>
      <Dialog
        open={Boolean(file) && Boolean(previewDialog)}
        TransitionComponent={DialogTransition}
        fullScreen
      >
        {previewDialog && file && (
          <PreviewPanel file={file} onClose={onClose} />
        )}
      </Dialog>
    </Box>
  );
};

export default PageWithPreview;
