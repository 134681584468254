import React from "react";
import * as Yup from "yup";
import { KeyboardDatePicker, Radios } from "mui-rff";
import { makeValidate, makeRequired } from "mui-rff";
import { Grid, makeStyles, Button, Theme } from "@material-ui/core";
import { isEqual } from "date-fns";
import {
  set,
  subDays,
  addMonths,
  addYears,
  subMonths,
  subYears,
} from "date-fns/fp";
import { useForm, useFormState } from "react-final-form";
import useMessage from "lib/hooks/useMessage";

const onlyDate = set({
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});
const now = onlyDate(new Date());
const nowMonth = set({
  date: 1,
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
})(new Date());
const nowYear = set({
  month: 0,
  date: 1,
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
})(new Date());
const subOneDay = subDays(1);
const subSevenDays = subDays(7);
const addOneMonth = addMonths(1);
const addOneYear = addYears(1);
const subOneMonth = subMonths(1);
const subOneYear = subYears(1);

const useStyles = makeStyles((theme: Theme) => ({
  inlineRadios: {
    flexDirection: "row",
  },
  dateShortcut: {
    margin: theme.spacing(0.5),
  },
}));

export interface InvoiceFiltersModel {
  from?: Date;
  to?: Date;
  paid: "all" | "paid" | "due";
}

const schema = Yup.object().shape<InvoiceFiltersModel>({
  from: Yup.date(),
  to: Yup.date(),
  paid: Yup.mixed().oneOf(["all", "paid", "due"]),
});

export const invoiceFiltersValidate = makeValidate(schema);
const required = makeRequired(schema);

const dateShortcuts = [
  {
    label: "invoices.filter.date7days",
    from: subSevenDays(now),
    to: now,
  },
  {
    label: "invoices.filter.month",
    from: nowMonth,
    to: subOneDay(addOneMonth(nowMonth)),
  },
  {
    label: "invoices.filter.lastmonth",
    from: subOneMonth(nowMonth),
    to: subOneDay(nowMonth),
  },
  {
    label: "invoices.filter.year",
    from: nowYear,
    to: subOneDay(addOneYear(nowYear)),
  },
  {
    label: "invoices.filter.lastyear",
    from: subOneYear(nowYear),
    to: subOneDay(nowYear),
  },
];

const InvoiceFilters: React.FunctionComponent<{}> = () => {
  const t = useMessage();
  const classes = useStyles();
  const form = useForm();
  const formState = useFormState();

  const stateFrom = onlyDate(formState.values["from"]);
  const stateTo = onlyDate(formState.values["to"]);

  const isSelected = React.useCallback(
    (from: Date, to: Date) => {
      return (
        stateFrom && stateTo && isEqual(stateFrom, from) && isEqual(stateTo, to)
      );
    },
    [stateFrom, stateTo]
  );

  const handleDateClick = (from: Date, to: Date) => () => {
    form.change("from", from);
    form.change("to", to);
  };

  return (
    <Grid spacing={2} container>
      <Grid xs={12} sm={6} item>
        <KeyboardDatePicker
          format="dd/MM/yyyy"
          label={t("invoices.filter.from")}
          required={required.from}
          name="from"
        />
        <KeyboardDatePicker
          format="dd/MM/yyyy"
          label={t("invoices.filter.to")}
          required={required.to}
          name="to"
        />
      </Grid>
      <Grid xs={12} sm={6} item>
        {dateShortcuts.map((v, i) => (
          <Button
            key={i}
            className={classes.dateShortcut}
            variant={isSelected(v.from, v.to) ? "contained" : "outlined"}
            color={isSelected(v.from, v.to) ? "primary" : "default"}
            size="small"
            onClick={handleDateClick(v.from, v.to)}
          >
            {t(v.label)}
          </Button>
        ))}
      </Grid>
      <Grid xs={12} item>
        <Radios
          label={t("invoices.filter.state")}
          name="paid"
          radioGroupProps={{ className: classes.inlineRadios }}
          required={required.paid}
          data={[
            { label: t("invoices.filter.stateall"), value: "all" },
            { label: t("invoices.filter.statepaid"), value: "paid" },
            { label: t("invoices.filter.statedue"), value: "due" },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default InvoiceFilters;
