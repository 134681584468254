import React from "react";
import Login from "./screens/Login";
import AppRouter from "./AppRouter";
import { Stitch, StitchAuth, StitchUser } from "mongodb-stitch-browser-sdk";
import { IntlProvider } from "react-intl";
import fr from "./locales/fr.json";
import { CssBaseline } from "@material-ui/core";

Stitch.initializeDefaultAppClient(process.env.REACT_APP_STITCH_ID || "");

const App: React.FunctionComponent<{}> = () => {
  const [user, setUser] = React.useState<StitchUser | undefined>();

  const authListener = React.useMemo(
    () => ({
      onUserLoggedIn: (auth: StitchAuth) => {
        if (auth.user?.customData["org"]) {
          setUser(auth.user);
        } else {
          Stitch.defaultAppClient.auth.logout();
        }
      },
      onUserLoggedOut: (auth: StitchAuth) => {
        setUser(auth.user);
      },
    }),
    []
  );

  React.useEffect(() => {
    Stitch.defaultAppClient.auth.addAuthListener(authListener);
    return () => {
      Stitch.defaultAppClient.auth.removeAuthListener(authListener);
    };
  }, [authListener]);

  if (
    Stitch.defaultAppClient.auth.user &&
    !Stitch.defaultAppClient.auth.user.customData["org"]
  ) {
    Stitch.defaultAppClient.auth.refreshCustomData().then(() => {
      setUser(Stitch.defaultAppClient.auth.user);
    });
    return <div></div>;
  }

  return (
    <IntlProvider locale="fr" messages={fr}>
      <CssBaseline />
      <div className={user ? "App App-logged" : "App"}>
        {user && <AppRouter />}
        {!user && <Login />}
      </div>
    </IntlProvider>
  );
};

export default App;
