import React from "react";
import {
  makeStyles,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Theme,
} from "@material-ui/core";
import { NoteAdd as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { DbInvoice } from "lib/db/Models";
import InvoiceRepository from "lib/db/InvoiceRepository";
import { SORT_DIRECTION, indexById } from "lib/db/utils";
import InvoiceItem from "./InvoiceItem";
import InvoicesUtils from "./InvoicesUtils";
import InvoiceFilters, { InvoiceFiltersModel } from "./InvoiceFilters";
import ListHeader from "components/ListHeader";
import { FormattedMessage } from "react-intl";
import ContactRepository from "lib/db/ContactRepository";
import { useScreenStack } from "components/ScreenStack";
import useMessage from "lib/hooks/useMessage";
import InvoiceForm from "./InvoiceForm";

export type onSelectCallback = (value: DbInvoice | undefined | null) => void;

const useStyles = makeStyles((theme: Theme) => ({
  buttonSpacing: {
    marginLeft: theme.spacing(2),
  },
}));

interface Props {
  onSelect: onSelectCallback;
}

const InvoiceSelect: React.FunctionComponent<Props> = ({ onSelect }) => {
  const [sort, setSort] = React.useState(SORT_DIRECTION.SORT_DESC);
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState<InvoiceFiltersModel>({
    from: undefined,
    to: undefined,
    paid: "all",
  });
  const stack = useScreenStack();
  const classes = useStyles();
  const t = useMessage();

  const [invoices, isLoading, load] = InvoiceRepository.useMany(
    {},
    { date: SORT_DIRECTION.SORT_DESC }
  );
  const [contacts] = ContactRepository.useMany();

  const contactsById = React.useMemo(() => {
    return indexById(contacts);
  }, [contacts]);

  const data = React.useMemo(() => {
    if (!search) {
      return invoices;
    }
    return invoices.filter(
      invoice =>
        (invoice.folder &&
          invoice.folder.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
        invoice.ref.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  }, [invoices, search]);

  const handleSort = (direction: SORT_DIRECTION) => {
    setSort(direction);
    load(InvoicesUtils.buildQuery(filter), { date: direction });
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleFilter = (value: InvoiceFiltersModel) => {
    setFilter(value);
    load(InvoicesUtils.buildQuery(value), { date: sort });
  };

  const handleAddInvoice = (value?: DbInvoice) => {
    onSelect(value);
  };

  return (
    <>
      {isLoading && data.length === 0 ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <List dense>
          <ListHeader
            sort={sort}
            onSort={handleSort}
            search={search}
            onSearch={handleSearch}
            filter={filter}
            onFilter={handleFilter}
          >
            <InvoiceFilters />
          </ListHeader>
          {data.map(invoice => (
            <InvoiceItem
              key={invoice._id!.toHexString()}
              invoice={invoice}
              contact={contactsById[invoice.contactId?.toHexString()]}
              onEdit={onSelect}
            />
          ))}
          {data.length === 0 && (
            <ListItem>
              <ListItemText>
                <FormattedMessage id="invoices.noresults" />
              </ListItemText>
            </ListItem>
          )}
        </List>
      )}
      <Box textAlign="right">
        <Button
          color="default"
          variant="text"
          onClick={() => onSelect(undefined)}
        >
          <FormattedMessage id="cancel" />
        </Button>
        <IconButton onClick={() => onSelect(null)} color="secondary">
          <DeleteIcon />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            stack.push(t("invoices.create"), <InvoiceForm />, handleAddInvoice)
          }
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="appbar.customer-invoices" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            stack.push(
              t("invoices.create"),
              <InvoiceForm supplier={true} />,
              handleAddInvoice
            )
          }
          startIcon={<AddIcon />}
          className={classes.buttonSpacing}
        >
          <FormattedMessage id="appbar.supplier-invoices" />
        </Button>
      </Box>
    </>
  );
};

export default InvoiceSelect;
