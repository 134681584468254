import React from "react";
import {
  makeStyles,
  Theme,
  Fab,
  Container,
  List,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import TopProgress from "components/TopProgress";
import UserItem from "./UserItem";
import useMessage from "lib/hooks/useMessage";
import { useHistory } from "react-router-dom";
import UserRepository from "lib/db/UserRepository";
import { DbUser } from "lib/db/Models";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Users: React.FunctionComponent<{}> = () => {
  const [users, isLoading] = UserRepository.useMany();
  const history = useHistory();
  const t = useMessage();
  const classes = useStyles();

  const handleAdd = () => {
    history.push("/user");
  };

  const handleEdit = async (user: DbUser) => {
    history.push("/user/" + user._id);
  };

  return (
    <div className={classes.root}>
      <TopProgress show={isLoading} />
      <Container maxWidth="sm" disableGutters>
        <List dense>
          {users.map(user => (
            <UserItem
              key={user._id!.toHexString()}
              user={user}
              onEdit={handleEdit}
            />
          ))}
        </List>
      </Container>
      <Fab
        aria-label={t("users.add")}
        className={classes.fab}
        color="primary"
        onClick={handleAdd}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default Users;
