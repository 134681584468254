import React from "react";
import { Marker } from "react-mapbox-gl";
import { Props as MarkerProps } from "react-mapbox-gl/lib/marker";
import { Room as PoiIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  clickable: {
    cursor: "pointer",
  },
  icon: {
    transform: "translateY(-10px)",
  },
});

export interface MapMarkerProps extends MarkerProps {
  active?: boolean;
}

const MapMarker: React.FunctionComponent<MapMarkerProps> = ({
  active,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Marker className={classes.clickable} {...rest}>
      <PoiIcon
        htmlColor={active ? "#3f51b5" : "#FF0000"}
        className={classes.icon}
        fontSize="large"
      />
    </Marker>
  );
};

export default MapMarker;
