import React from "react";
import BankStatementRepository from "lib/db/BankStatementRepository";
import { Add as AddIcon } from "@material-ui/icons";
import { makeStyles, Theme, Fab, Container, List } from "@material-ui/core";
import TopProgress from "components/TopProgress";
import { useHistory } from "react-router-dom";
import BankStatementItem from "./BankStatementItem";
import ListHeader from "components/ListHeader";
import { SORT_DIRECTION } from "lib/db/utils";
import { DbBankStatement } from "lib/db/Models";
import useMessage from "lib/hooks/useMessage";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function prepareData(
  data: DbBankStatement[],
  search: string
): DbBankStatement[] {
  return data.reduce<DbBankStatement[]>((prev, cur) => {
    if (
      search &&
      !(cur.label.toLowerCase().indexOf(search.toLowerCase()) > -1)
    ) {
      // Filtered
      return prev;
    }
    return [...prev, cur];
  }, []);
}

const BankStatements: React.FunctionComponent<{}> = () => {
  const [sort, setSort] = React.useState(SORT_DIRECTION.SORT_DESC);
  const [search, setSearch] = React.useState("");
  const [bankStatements, isLoading, load] = BankStatementRepository.useMany(
    {},
    { date: SORT_DIRECTION.SORT_DESC }
  );
  const history = useHistory();
  const t = useMessage();
  const classes = useStyles();

  const data = React.useMemo(() => {
    return prepareData(bankStatements, search);
  }, [bankStatements, search]);

  const handeAdd = () => {
    history.push("/bankstatement");
  };

  const handleEdit = (id: string) => {
    history.push("/bankstatement/" + id);
  };

  const handleSort = (direction: SORT_DIRECTION) => {
    setSort(direction);
    load({}, { date: direction });
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <div className={classes.root}>
      <TopProgress show={isLoading} />
      <Container maxWidth="sm" disableGutters>
        <List dense>
          <ListHeader
            sort={sort}
            onSort={handleSort}
            search={search}
            onSearch={handleSearch}
          />
          {data.map(bs => (
            <BankStatementItem
              key={bs._id!.toHexString()}
              bankStatement={bs}
              onEdit={handleEdit}
            />
          ))}
        </List>
      </Container>
      <Fab
        aria-label={t("bankstatements.add")}
        className={classes.fab}
        color="primary"
        onClick={handeAdd}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default BankStatements;
