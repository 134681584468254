import React from "react";
import EditCard from "components/EditCard";
import { useHistory, useParams } from "react-router-dom";
import useMessage from "lib/hooks/useMessage";
import AssetRepository from "lib/db/AssetRepository";
import AssetFields, { assetFieldsValidate } from "./AssetFields";
import { GeoLocation, DbAsset } from "lib/db/Models";
import { StorageFile } from "lib/Storage";

interface Params {
  id?: string;
}

interface Props {
  address?: string;
  location: GeoLocation;
  onSaved: (asset?: DbAsset) => void;
  onFileSelect: (file: StorageFile) => void;
}

const AssetForm: React.FunctionComponent<Props> = ({
  address,
  location,
  onSaved,
  onFileSelect,
}) => {
  const [title, setTitle] = React.useState("Création bien");
  const [asset, isLoading, load] = AssetRepository.useOne();
  const params = useParams<Params>();
  const history = useHistory();
  const t = useMessage();

  const handleCancel = () => {
    history.push("/assets");
  };

  const handleSave = (values: any) => {
    const { longitude, latitude, addressSearch, ...obj } = values;
    let coordinates = [longitude, latitude];
    if (addressSearch) {
      coordinates = addressSearch.center;
      obj.address = addressSearch.place_name;
    }
    return AssetRepository.save({
      ...obj,
      location: { type: "Point", coordinates },
    }).then(o => {
      onSaved(o);
      history.push("/assets");
    });
  };

  let handleDelete;
  if (asset && asset._id) {
    handleDelete = () => {
      return AssetRepository.delete(asset._id!).then(() => {
        onSaved();
        history.push("/assets");
      });
    };
  }

  React.useEffect(() => {
    if (params.id) {
      setTitle(t("assets.edit"));
      load(params.id);
    } else {
      setTitle(t("assets.create"));
      load();
    }
  }, [params.id, load, t]);

  const values = React.useMemo(() => {
    if (asset) {
      const { location, ...rest } = asset;
      return {
        ...rest,
        longitude: location.coordinates[0],
        latitude: location.coordinates[1],
      };
    } else {
      return {
        address,
        longitude: location[0],
        latitude: location[1],
        date: new Date().toISOString().substr(0, 10),
      };
    }
  }, [asset, address, location]);

  return (
    <EditCard
      title={title}
      values={values}
      loading={isLoading}
      onValidate={assetFieldsValidate}
      onCancel={handleCancel}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <AssetFields onFileSelect={onFileSelect} />
    </EditCard>
  );
};

export default AssetForm;
