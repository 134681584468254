import { InvoiceFiltersModel } from "./InvoiceFilters";

function buildQuery(filter: InvoiceFiltersModel, supplier?: boolean) {
  const qry: any = {};
  if (supplier !== undefined) {
    qry["supplier"] = supplier;
  }
  if (filter.from && filter.to) {
    qry["date"] = { $gte: filter.from, $lte: filter.to };
  } else if (filter.from) {
    qry["date"] = { $gte: filter.from };
  } else if (filter.to) {
    qry["date"] = { $lte: filter.to };
  }
  if (filter.paid === "paid") {
    qry["isPaid"] = true;
  } else if (filter.paid === "due") {
    qry["isPaid"] = false;
  }
  return qry;
}

export default { buildQuery };
