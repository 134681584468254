import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import InvoiceRepository from "lib/db/InvoiceRepository";
import EditCard from "components/EditCard";
import {
  makeStyles,
  Theme,
  InputLabel,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
  List,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import createDecorator from "final-form-calculate";
import InvoiceFields, { invoiceFieldsValidate } from "./InvoiceFields";
import { MutableState, Tools } from "final-form";
import useMessage from "lib/hooks/useMessage";
import BankStatementRepository from "lib/db/BankStatementRepository";
import { useScreenStack } from "components/ScreenStack";

const date = new DateFnsUtils();

const useStyles = makeStyles((theme: Theme) => ({
  bankStatementLabel: {
    marginTop: theme.spacing(1),
  },
}));

const calculator = createDecorator({
  field: ["total", "paid"],
  updates: {
    rest: (_current, values: any) => {
      if (isNaN(values.total)) {
        return;
      }
      if (isNaN(values.paid)) {
        return values.total;
      }
      return values.total - values.paid;
    },
  },
});

interface Params {
  id?: string;
}

interface Props {
  supplier?: boolean;
}

const InvoiceForm: React.FunctionComponent<Props> = ({ supplier }) => {
  supplier = supplier || false;
  const [title, setTitle] = React.useState("");
  const [invoice, isLoading, load] = InvoiceRepository.useOne();
  const [
    bankStatements,
    ,
    bankStatementsLoad,
  ] = BankStatementRepository.useMany({}, { date: -1 }, { loadNow: false });
  const params = useParams<Params>();
  const history = useHistory();
  const stack = useScreenStack();
  const t = useMessage();
  const classes = useStyles();

  React.useEffect(() => {
    if (invoice) {
      bankStatementsLoad({ "entries.invoiceId": invoice?._id });
    }
  }, [invoice, bankStatementsLoad]);

  const handleCancel = () => {
    if (stack.pop) {
      stack.pop();
    } else {
      history.push(supplier ? "/supplier-invoices" : "/customer-invoices");
    }
  };

  const handleSave = (values: any) => {
    values = {
      ...values,
      total: parseFloat(values.total),
      paid: parseFloat(values.paid || 0),
      supplier,
    };
    return InvoiceRepository.save(values)
      .then(o => {
        if (stack.pop) {
          stack.pop(o);
        } else {
          history.push(supplier ? "/supplier-invoices" : "/customer-invoices");
        }
      })
      .catch(console.error);
  };

  let handleDelete;
  if (invoice && invoice._id) {
    handleDelete = () => {
      return InvoiceRepository.delete(invoice._id!).then(() =>
        history.push(supplier ? "/supplier-invoices" : "/customer-invoices")
      );
    };
  }

  React.useEffect(() => {
    if (params.id && !stack.pop) {
      setTitle(t("invoices.edit"));
      load(params.id);
    } else {
      setTitle(t("invoices.create"));
      load();
    }
  }, [params.id, load, t, stack.pop]);

  const mutatorSetContactId = (
    args: string[],
    state: MutableState<{}>,
    tools: Tools<{}>
  ) => {
    tools.changeValue(state, "contactId", () => args[0]);
  };

  const values = React.useMemo(
    () => ({
      ...invoice,
      contactId: invoice?.contactId?.toHexString(),
      bankStatementsId: invoice?.bankStatementsId
        ? invoice?.bankStatementsId
        : [],
    }),
    [invoice]
  );

  return (
    <EditCard
      title={title}
      values={values}
      loading={isLoading}
      decorators={[calculator]}
      mutators={{ setContactId: mutatorSetContactId }}
      onValidate={invoiceFieldsValidate}
      onCancel={handleCancel}
      onSave={handleSave}
      onDelete={handleDelete}
    >
      <InvoiceFields
        parentLoading={isLoading}
        contactLabel={
          supplier ? "invoices.field.supplier" : "invoices.field.customer"
        }
      />
      {bankStatements.length > 0 && (
        <>
          <InputLabel className={classes.bankStatementLabel} shrink={true}>
            {t("invoices.field.bankStatement")}
          </InputLabel>
          <List dense>
            {bankStatements.map(bs => {
              if (bs.entries) {
                return bs.entries.map(entry => {
                  if (
                    entry.invoiceId?.toHexString() ===
                    invoice?._id?.toHexString()
                  ) {
                    const primary = (
                      <Box display="flex">
                        <Box flexGrow="1">{bs.label}</Box>
                        <Box>
                          <Typography variant="body2">
                            {date.format(bs.date, "dd/MM/yyyy")}
                          </Typography>
                        </Box>
                      </Box>
                    );
                    const secondary = (
                      <Box display="flex">
                        <Box flexGrow="1">
                          {date.format(entry.date, "dd/MM/yyyy")} —{" "}
                          <strong>{entry.label}</strong>
                        </Box>
                        <Box>
                          <Typography color="textSecondary" variant="body2">
                            {entry.amount.toFixed(2)}€
                          </Typography>
                        </Box>
                      </Box>
                    );
                    return (
                      <React.Fragment key={`${entry._id?.toHexString()}`}>
                        <ListItem>
                          <ListItemText
                            primary={primary}
                            secondary={secondary}
                            secondaryTypographyProps={{ component: "span" }}
                          />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    );
                  }
                  return undefined;
                });
              }
              return undefined;
            })}
          </List>
        </>
      )}
    </EditCard>
  );
};

export default InvoiceForm;
