import React from "react";
import InvoiceRepository from "lib/db/InvoiceRepository";
import { Add as AddIcon } from "@material-ui/icons";
import {
  makeStyles,
  Theme,
  Fab,
  List,
  Container,
  ListItemText,
  ListItem,
  TextField,
  Grid,
} from "@material-ui/core";
import { DbInvoice } from "lib/db/Models";
import TopProgress from "components/TopProgress";
import { useHistory } from "react-router-dom";
import InvoiceItem from "./InvoiceItem";
import ContactRepository from "lib/db/ContactRepository";
import { indexById, SORT_DIRECTION } from "lib/db/utils";
import ListHeader from "components/ListHeader";
import InvoiceFilters, { InvoiceFiltersModel } from "./InvoiceFilters";
import { StorageFile } from "lib/Storage";
import PageWithPreview from "components/PageWithPreview";
import { FormattedMessage } from "react-intl";
import useMessage from "lib/hooks/useMessage";
import InvoicesUtils from "./InvoicesUtils";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
  content: {
    padding: theme.spacing(2),
  },
  widthTransition: {
    transition: "width 0.5s ease-out",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  total: {
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "right",
  },
  totalLabel: {
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

interface Data {
  total: number;
  paid: number;
  due: number;
  invoices: DbInvoice[];
}

function prepareData(invoice: DbInvoice[], search: string): Data {
  return invoice.reduce<Data>(
    (prev, cur) => {
      if (
        search &&
        !(
          (cur.folder &&
            cur.folder.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
          cur.ref.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
      ) {
        // Filtered
        return prev;
      }
      prev.total += cur.total;
      prev.paid += cur.paid;
      prev.due += cur.total - cur.paid;
      prev.invoices.push(cur);
      return prev;
    },
    {
      total: 0,
      paid: 0,
      due: 0,
      invoices: [],
    }
  );
}

interface Props {
  supplier: boolean;
}

const Invoices: React.FunctionComponent<Props> = ({ supplier }) => {
  const [previewDocument, setPreviewDocument] = React.useState<StorageFile>();
  const [previewDialog, setPreviewDialog] = React.useState(false);
  const [sort, setSort] = React.useState(SORT_DIRECTION.SORT_DESC);
  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState<InvoiceFiltersModel>({
    from: undefined,
    to: undefined,
    paid: "all",
  });
  const [invoices, isLoading, load] = InvoiceRepository.useMany(
    { supplier },
    { date: SORT_DIRECTION.SORT_DESC }
  );
  const [contacts, isLoadingContacts] = ContactRepository.useMany();
  const history = useHistory();
  const t = useMessage();
  const classes = useStyles();

  const contactsById = React.useMemo(() => {
    return indexById(contacts);
  }, [contacts]);

  const data = React.useMemo(() => {
    return prepareData(invoices, search);
  }, [invoices, search]);

  const handeAdd = () => {
    history.push(supplier ? "/supplier-invoice" : "/customer-invoice");
  };

  const handleEdit = (invoice: DbInvoice) => {
    history.push(
      (supplier ? "/supplier-invoice" : "/customer-invoice") +
        "/" +
        invoice._id!.toHexString()
    );
  };

  const handleView = (file: StorageFile, mdDown: boolean) => {
    setPreviewDialog(mdDown);
    setPreviewDocument(file);
  };

  const handleSort = (direction: SORT_DIRECTION) => {
    setSort(direction);
    load(InvoicesUtils.buildQuery(filter, supplier), { date: direction });
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleFilter = (value: InvoiceFiltersModel) => {
    setFilter(value);
    load(InvoicesUtils.buildQuery(value, supplier), { date: sort });
  };

  const handlePreviewClose = () => {
    setPreviewDocument(undefined);
  };

  return (
    <PageWithPreview
      className={classes.root}
      file={previewDocument}
      previewDialog={previewDialog}
      onClose={handlePreviewClose}
    >
      <TopProgress show={isLoading || isLoadingContacts} />
      <Container maxWidth="sm" disableGutters>
        <List dense>
          <ListHeader
            sort={sort}
            onSort={handleSort}
            search={search}
            onSearch={handleSearch}
            filter={filter}
            onFilter={handleFilter}
          >
            <InvoiceFilters />
          </ListHeader>
          {data.invoices.length > 0 && (
            <ListItem>
              <ListItemText>
                <Grid spacing={2} container>
                  <Grid xs={4} item>
                    <TextField
                      label={t("invoices.total")}
                      variant="outlined"
                      size="small"
                      value={data.total.toFixed(2) + "€"}
                      InputLabelProps={{
                        classes: { shrink: classes.totalLabel },
                      }}
                      InputProps={{ classes: { disabled: classes.total } }}
                      disabled
                    />
                  </Grid>
                  <Grid xs={4} item>
                    <TextField
                      label={t("invoices.totalpaid")}
                      variant="outlined"
                      size="small"
                      value={data.paid.toFixed(2) + "€"}
                      InputLabelProps={{
                        classes: { shrink: classes.totalLabel },
                      }}
                      InputProps={{ classes: { disabled: classes.total } }}
                      disabled
                    />
                  </Grid>
                  <Grid xs={4} item>
                    <TextField
                      label={t("invoices.totaldue")}
                      variant="outlined"
                      size="small"
                      value={data.due.toFixed(2) + "€"}
                      InputLabelProps={{
                        classes: { shrink: classes.totalLabel },
                      }}
                      InputProps={{ classes: { disabled: classes.total } }}
                      disabled
                    />
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          )}
          {!isLoadingContacts && !isLoading && data.invoices.length === 0 && (
            <ListItem>
              <ListItemText>
                <FormattedMessage id="invoices.noresults" />
              </ListItemText>
            </ListItem>
          )}
          {!isLoadingContacts &&
            data.invoices.map((invoice) => (
              <InvoiceItem
                key={invoice._id!.toHexString()}
                invoice={invoice}
                contact={contactsById[invoice.contactId?.toHexString()]}
                onEdit={handleEdit}
                onView={handleView}
              />
            ))}
        </List>
      </Container>
      <Fab
        aria-label={t("invoices.add")}
        className={classes.fab}
        color="primary"
        onClick={handeAdd}
      >
        <AddIcon />
      </Fab>
    </PageWithPreview>
  );
};

export default Invoices;
